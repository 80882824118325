import { useGetCustomerCrmContactsQuery } from 'models/graphql';

export const useGetCustomerCrmContacts = () => {
  const { data, error, loading } = useGetCustomerCrmContactsQuery({
    notifyOnNetworkStatusChange: true,
  });

  return {
    customerCrmContacts: data?.customerCrmContacts || { count: 0, items: [] },
    customerCrmContactsLoading: loading,
    customerCrmContactsError: error,
  };
};
