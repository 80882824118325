import Keycloak from 'keycloak-js';
import environment from './environment';

const keycloak = Keycloak({
  url: environment.KEYCLOAK_URL,
  realm: environment.REALM,
  clientId: environment.KEYCLOAK_CLIENT_ID,
});

export default keycloak;
