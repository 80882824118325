import React, { ReactNode } from 'react';
import Hotjar from '@hotjar/browser';
import environment from 'environment';

interface IOwnProps {
  children: ReactNode;
}

const HotjarProvider: React.FC<IOwnProps> = ({ children }) => {
  const hotjarSideId = environment.HOTJAR_SITE_ID;
  const hotjarVersion = environment.HOTJAR_VERSION;

  if (!hotjarSideId || !hotjarVersion) {
    return <>{children}</>;
  }

  return (
    <>
      {Hotjar.init(hotjarSideId, hotjarVersion)}
      {children}
    </>
  );
};

export default HotjarProvider;
