import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { renderRoutes, IRoutesProps } from 'utils';
import { useKeycloak } from '@react-keycloak/web';
import { WebpushManager } from '../components/WebPushManager/WebPushManager';
import Layout from '../Layout';
import Fallback from './Fallback';
import ScrollToTop from './ScrollToTop';

const DashboardPage = lazy(() => {
  return import('pages/DashboardPage');
});
const InboxPage = lazy(() => {
  return import('pages/InboxPage');
});
const ReportsPage = lazy(() => {
  return import('pages/ReportsPage');
});
const CustomerInfoPage = lazy(() => {
  return import('pages/CustomerInfoPage');
});
const InstructionsPage = lazy(() => {
  return import('pages/InstructionsPage');
});
const AttachmentsPage = lazy(() => {
  return import('pages/AttachmentsPage');
});
const AddressBookPage = lazy(() => {
  return import('pages/AddressBookPage');
});
const CalendarPage = lazy(() => {
  return import('pages/Calendar');
});
const InvoicesPage = lazy(() => {
  return import('pages/InvoicesPage');
});
const ConnectionsPage = lazy(() => {
  return import('pages/ConnectionsPage');
});
const UsersPage = lazy(() => {
  return import('pages/UsersPage');
});
const SearchPage = lazy(() => {
  return import('pages/SearchPage');
});
const VideoCallPage = lazy(() => {
  return import('pages/VideoReceptionPage/components/VideoReception/Video/Video');
});
const InstructionsDetailsPage = lazy(() => {
  return import('pages/FixedInstructionsPage/FixedInstructionsDetails');
});
const NewTempInstruction = lazy(() => {
  return import('pages/TempInstructionsPage/NewTempInstruction');
});
const ContactPage = lazy(() => {
  return import('pages/ContactPage');
});
const PrivacyPolicyPage = lazy(() => {
  return import('pages/PrivacyPolicyPage');
});

// TODO add role-based access
const routes: IRoutesProps[] = [
  {
    name: 'DashboardPage',
    path: '/',
    Component: DashboardPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'InboxPage',
    path: '/messages/call-center/:id?',
    Component: InboxPage,
    restricted: true,
  },
  {
    name: 'SocialMediaInboxPage',
    path: '/messages/social-networks/:id?',
    Component: InboxPage,
    restricted: true,
  },
  {
    name: 'CalendarPage',
    path: '/calendar',
    Component: CalendarPage,
    restricted: true,
  },
  {
    name: 'InvoicesPage',
    path: '/invoices',
    Component: InvoicesPage,
    restricted: true,
  },
  {
    name: 'ReportsPage',
    path: '/reports/:tab?',
    Component: ReportsPage,
    restricted: true,
  },
  {
    name: 'CustomerInfoPage',
    path: '/customer/info',
    Component: CustomerInfoPage,
    restricted: true,
  },
  {
    name: 'InstructionsPage',
    path: '/customer/instructions/:type',
    Component: InstructionsPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'InstructionsDetailsPage',
    path: '/customer/instructions/fixed/:instructionId',
    Component: InstructionsDetailsPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'ModifyTempInstruction',
    path: '/customer/instructions/temporary/:updateType/:instructionId?',
    Component: NewTempInstruction,
    exact: true,
    restricted: true,
  },
  {
    name: 'AttachmentsPage',
    path: '/customer/attachments',
    Component: AttachmentsPage,
    restricted: true,
  },
  {
    name: 'AddressBookPage',
    path: '/customer/crmcontacts',
    Component: AddressBookPage,
    restricted: true,
  },
  {
    name: 'ConnectionsPage',
    path: '/settings/connections/:tab?',
    Component: ConnectionsPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'VideoCallPage',
    path: '/call/:callId',
    Component: VideoCallPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'UsersPage',
    path: '/settings/users',
    Component: UsersPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'SearchPage',
    path: '/search',
    Component: SearchPage,
    restricted: true,
  },
  {
    name: 'ContactPage',
    path: '/contact',
    Component: ContactPage,
    exact: true,
    restricted: true,
  },
  {
    name: 'PrivacyPolicyPage',
    path: '/privacy-policy',
    Component: PrivacyPolicyPage,
    exact: true,
    restricted: false,
  },
];

const MainRouter: React.FC = () => {
  const { keycloak } = useKeycloak();

  return (
    <Router>
      <WebpushManager>
        <ScrollToTop>
          <Suspense fallback={<Fallback />}>
            {keycloak.authenticated ? (
              <Layout>
                <Switch>{renderRoutes(routes)}</Switch>
              </Layout>
            ) : (
              <Switch>{renderRoutes(routes)}</Switch>
            )}
          </Suspense>
        </ScrollToTop>
      </WebpushManager>
    </Router>
  );
};

export default MainRouter;
