import { CustomerCrmContactCommunicationTypeCategories } from '../../models/graphql';
import { CrmInformationType, ICrmContactSelectorProps } from './CrmContactSelector';

export const useCrmContactOptions = (
  config: Pick<ICrmContactSelectorProps, 'crmContacts' | 'informationType'>,
) => {
  const { crmContacts, informationType } = config;

  if (!crmContacts) {
    return [];
  }

  const mapper = {
    [CrmInformationType.Phonenumber]: CustomerCrmContactCommunicationTypeCategories.Mobile,
    [CrmInformationType.Email]: CustomerCrmContactCommunicationTypeCategories.Email,
  };

  return crmContacts.items.map((contact, index) => {
    const { firstName, lastName, communications } = contact;
    const phones =
      communications?.filter((communication) => {
        return communication.value && communication.type.category === mapper[informationType];
      }) || [];

    return {
      key: `${index}${firstName}`,
      label: `${firstName} ${lastName}`,
      options: phones.map((phone, phoneIndex) => {
        return {
          key: `${phone.value}-${phoneIndex}-${index}`,
          value: phone.value || '',
          content: phone.value,
        };
      }),
    };
  });
};
