import { AVAILABLE_ROLES } from '..';
import keycloak from '../../keycloak';

const getRoles = () => {
  if (!keycloak) {
    return [];
  }

  if (!keycloak.tokenParsed) {
    return [];
  }
  const roles = keycloak.tokenParsed.realm_access?.roles || [];
  const availableRolesArray = [
    AVAILABLE_ROLES.ADMIN,
    AVAILABLE_ROLES.AGENT,
    AVAILABLE_ROLES.SUPERADMIN,
  ];

  const noAvailable = availableRolesArray.every((role) => {
    return !roles.includes(role);
  });

  if (noAvailable) {
    roles.push(AVAILABLE_ROLES.AGENT);
  }

  return roles;
};

export const getPermission = (rules: string[]) => {
  const roles = getRoles();

  return rules.some((rule) => {
    return roles.includes(rule);
  });
};
