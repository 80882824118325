import React from 'react';
import { DayNightCallTypes } from 'models/graphql';
import { Icon } from '../index';

export const iconMapper = {
  [DayNightCallTypes.Day]: 'sun',
  [DayNightCallTypes.Night]: 'moon',
};

const DayNightIcon = ({ val }: { val: DayNightCallTypes }) => {
  return <Icon name={iconMapper[val]} />;
};

export default DayNightIcon;
