import React, { ReactNode, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

interface IPrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute = ({ children }: IPrivateRouteProps) => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak.authenticated]);

  return <>{children}</>;
};

export default PrivateRoute;
