import { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { IObjectLiteral } from '../../../interfaces/object-literal.interface';
import {
  useAddMeccaConversationAgentMessageMutation,
  useAddMeccaConversationAttachmentMutation,
} from '../../../models/graphql';
import { useTranslation } from '../../../utils';
import { validateEmails, validatePhoneNumbers } from '../../../utils/global/helpers';
import { isError } from '../../../utils/global/isError';

export interface ISendMessageOptions {
  messageId?: string;
  messageTxt?: string;
  fileList: Array<RcFile>;
  phones: string[];
  emails: string[];
}

export interface IStatusMessage {
  status: 'success' | 'error' | 'init';
  message: string;
}

export type ForwardOptions = Partial<Pick<ISendMessageOptions, 'emails' | 'phones'>>;

export const useSendMessage = (
  { fileList, messageId, messageTxt, emails, phones }: ISendMessageOptions,
  _?: () => Promise<IObjectLiteral>,
) => {
  const [statusMessage, setStatusMessage] = useState<IStatusMessage>({
    status: 'init',
    message: '',
  });
  const translate = useTranslation();
  const [addMeccaAttachment] = useAddMeccaConversationAttachmentMutation({
    context: {
      hasUpload: true,
    },
  });
  const [
    addConversationAgentMessage,
    { loading: addConversationAgentMessageLoading, error: messageSendError },
  ] = useAddMeccaConversationAgentMessageMutation();

  const uploadFileRequest = async () => {
    if (!fileList?.length) {
      return true;
    }

    const response = await addMeccaAttachment({
      variables: {
        input: {
          messageId: parseInt(String(messageId), 10),
          attachments: fileList,
        },
      },
    });

    return response.data?.addMeccaMessageAttachment;
  };

  const sendMessageToMemoTech = () => {
    return sendMessage(false, true);
  };

  const sendMessageToEmail = () => {
    const isValid = validateEmails(emails);

    if (!isValid) {
      return null;
    }

    return sendMessage(false, false, {
      emails,
    });
  };

  const sendMessageViaSMS = () => {
    const isValid = validatePhoneNumbers(phones);

    if (!isValid) {
      return null;
    }

    return sendMessage(false, false, {
      phones,
    });
  };

  const sendMessage = async (
    shouldSaveAttachments = false,
    answer = false,
    forward: ForwardOptions = {},
  ) => {
    if (!messageId) {
      return;
    }

    const responseUploadFile = shouldSaveAttachments ? await uploadFileRequest() : true;

    if (messageSendError) {
      setStatusMessage({
        status: 'error',
        message: translate('form.pleaseCheckAllFields'),
      });

      return;
    }

    try {
      const response = await addConversationAgentMessage({
        variables: {
          agentMessageId: messageId,
          conversationId: messageId,
          input: {
            answer,
            forward,
            attachments: [],
            content: messageTxt,
            fieldValues: [],
          },
        },
      });

      if (
        response &&
        response.data?.addMeccaConversationAgentMessage.success &&
        responseUploadFile
      ) {
        setStatusMessage({
          status: 'success',
          message: translate('createdSuccesfully'),
        });
      } else {
        setStatusMessage({
          status: 'error',
          message: translate('somethingWentWrong'),
        });
      }
    } catch (err) {
      if (isError(err)) {
        setStatusMessage({
          status: 'error',
          message: translate(err.message),
        });
      }
    }
  };

  return {
    sendMessage,
    statusMessage,
    sendMessageToEmail,
    sendMessageViaSMS,
    sendMessageToMemoTech,
    loading: addConversationAgentMessageLoading,
  };
};
