import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';
import { IRoutesProps } from 'utils';

export const renderRoutes = (routes: IRoutesProps[]): React.ReactNode => {
  return routes.map(({ name, path, Component, exact, restricted }) => {
    const publicRoute = () => {
      return <Component />;
    };

    const restrictedRoute = () => {
      return (
        <PrivateRoute>
          <Component />
        </PrivateRoute>
      );
    };

    return (
      <Route
        key={name}
        exact={exact}
        path={path}
        component={restricted ? restrictedRoute : publicRoute}
      />
    );
  });
};
