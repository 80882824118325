import * as React from 'react';
import environment from '../../environment';
import { RegisterWebpushMutationVariables } from '../../models/graphql';
import { Logger } from '../../utils';
import { useRegisterWebpush } from './hooks/registerWebPush.hook';

export interface IWebpushManagerProps {}

export const WebpushManager: React.FunctionComponent<IWebpushManagerProps> = (props) => {
  const { registerWebpush } = useRegisterWebpush();

  const registerWebpushSubscription = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        let pushSubscription = await serviceWorkerRegistration.pushManager.getSubscription();

        if (pushSubscription === null) {
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            pushSubscription = await subscribeToPush();
          }
        }

        if (pushSubscription) {
          const { keys, endpoint } = pushSubscription.toJSON();

          if (keys && endpoint) {
            const variables: RegisterWebpushMutationVariables = {
              input: {
                endpoint,
                keys: {
                  p256dh: keys['p256dh'],
                  auth: keys['auth'],
                },
              },
            };

            registerWebpush(variables);
          }
        }
      } catch (err) {
        Logger.error(err);
      }
    }
  };

  const subscribeToPush = (): Promise<PushSubscription> => {
    return new Promise((resolve, reject) => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then((serviceWorkerRegistration) => {
            return serviceWorkerRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: environment.WEBPUSH_PUBLIC_KEY,
            });
          })
          .then((pushSubscription) => {
            resolve(pushSubscription);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  React.useEffect(() => {
    registerWebpushSubscription();
  }, []);

  return <>{props.children}</>;
};
