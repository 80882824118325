import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

export function htmlArrayToString(array: Array<string | React.ReactNode>): string {
  return array
    .map((item) => {
      if (typeof item === 'string') {
        return item;
      }

      return ReactDOMServer.renderToString(item as ReactElement);
    })
    .join(' ');
}
