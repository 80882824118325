import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Input, Select } from 'antd';
import { SearchTypes } from '../../models/graphql';
import { useTranslation } from '../../utils';
import './SearchWebsite.scss';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search } = Input;

interface ILocation {
  search: string;
  filter: string;
}

const SearchWebsite: React.FC = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const translate = useTranslation();
  const { search } = useLocation() as unknown as ILocation;
  const searchParam = new URLSearchParams(search);
  const searchedValue = searchParam.get('value') as string;
  const filteredValue = searchParam.get('filter') as string;
  const [searchValue, setSearchValue] = useState(searchedValue);
  const [collapsed, setCollapsed] = useState(true);

  const onChangeFilterHandler = (val: string) => {
    history.replace(
      `${match.path}search/?value=${'' || searchedValue}&filter=${val || filteredValue}`,
    );
  };

  const onSearchHandler = (val: string) => {
    history.replace(
      `${match.path}search/?value=${val}${filteredValue ? `&filter=${filteredValue}` : ''}`,
    );
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="search-select-container">
      <span
        className={`search-button${collapsed ? '' : ' active'}`}
        tabIndex={0}
        role="button"
        onClick={toggle}
      >
        <SearchOutlined />
      </span>
      <div className={`select-and-search${collapsed ? '' : ' active'}`}>
        <Select
          onChange={onChangeFilterHandler}
          size="large"
          defaultValue="all"
          className="main-select main-left-select search-select"
          dropdownClassName="main-select-dropdown"
        >
          <Option value="all">{translate('all')}</Option>
          <Option value={SearchTypes.Message}>{translate('inbox')}</Option>
          <Option value={SearchTypes.Agentmessage}>{translate('agentMessages')}</Option>
          <Option value={SearchTypes.Attachment}>{translate('attachments')}</Option>
          <Option value={SearchTypes.CustomerFixedInstruction}>{translate('instructions')}</Option>
          <Option value={SearchTypes.CustomerCrmContact}>{translate('crmContacts')}</Option>
        </Select>
        <Search
          size="large"
          placeholder={translate('searchInApplication')}
          onSearch={onSearchHandler}
          onChange={(e) => {
            return setSearchValue(e.target.value);
          }}
          className="main-search search-input"
          value={searchValue}
        />
      </div>
    </div>
  );
};

export default SearchWebsite;
