import React from 'react';
import { InfoSection, PageHeaderTabs } from 'components';
import { IRoutesProps } from 'utils';
import './PageHeader.scss';

interface IPageHeaderProps {
  title: string | JSX.Element;
  routes?: IRoutesProps[];
  actions?: JSX.Element;
  infoComponent?: JSX.Element;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ title, routes, actions, infoComponent }) => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__title">{title}</div>
        <div className="page-header__buttons">
          {routes && <PageHeaderTabs routes={routes} />}
          {actions}
        </div>
      </div>
      {infoComponent && <InfoSection>{infoComponent}</InfoSection>}
    </>
  );
};

export default PageHeader;
