/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, ApolloLink, from, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getMainDefinition } from '@apollo/client/utilities';
import { v4 } from 'uuid';
import { OperationDefinitionNode } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { StorageUtils, Logger } from '../utils';
import environment from '../environment';
import keycloak from '../keycloak';

const uri = `wss://${environment.SUB_URL}/graphql`;
const apiUri = `${environment.API_URL}/graphql`;

let id: string;
const getNewId = () => {
  id = v4();

  return id;
};

export const subscriptionClient = new SubscriptionClient(uri, {
  reconnect: true,
  timeout: 30000,
  inactivityTimeout: 5000,
  lazy: true,
  connectionParams: () => {
    const { token } = keycloak;

    return {
      authToken: token ? `Bearer ${token}` : undefined,
      clientId: !id ? getNewId() : id,
      selectedCustomer: StorageUtils.getCustomerId(),
    };
  },
});

const wsLink = new WebSocketLink(subscriptionClient);

const authLink = setContext((_, { headers }) => {
  const newHeaders = { ...headers };

  const { token } = keycloak;

  if (token) {
    newHeaders.Authorization = `Bearer ${token}`;
    newHeaders.selectedCustomer = StorageUtils.getCustomerId();
  }

  return { headers: newHeaders };
});

const batchLink = new BatchHttpLink({
  uri: apiUri,
  batchKey: (operation) => {
    return operation.operationName.toLowerCase().includes('conversation') ? 'slow' : 'normal';
  },
});

const httpLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;

    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  from([authLink, batchLink]),
);

const uploadLink = ApolloLink.from([
  authLink,
  createUploadLink({ uri: apiUri, includeExtensions: true }),
]);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      return Logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    Logger.error(`[Network error]: ${networkError}`);
  }
});

const link = ApolloLink.split(
  (operation) => {
    return operation.getContext().hasUpload;
  },
  uploadLink,
  httpLink,
);

export const client = new ApolloClient({
  link: from([errorLink, link]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
