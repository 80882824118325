import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Select } from 'antd';
import { useUpdateSingleConversation } from 'graphql/hooks/useUpdateSingleConversation';
import { InboxSource, MeccaConversationStatus } from 'types/inbox';
import { ConversationStatus } from 'models/graphql';
import { useTranslation } from 'utils';
import { StatusIndicator } from 'pages/DashboardPage/components/DashboardNotifications/DashboardNotification/StatusIndicator';
import { isError } from '../../utils/global/isError';
import { InboxContext } from '../../utils/context/InboxContext/inbox.context';
import './MessageStatus.scss';

interface IProps {
  id: string;
  status: string;
  loading?: boolean;
  multiIds?: boolean;
  ids?: Array<string>;
  disabled?: boolean;
}

const callCenterKey = 'call-center';

export const getStatusOptions = (
  enumType: typeof ConversationStatus | typeof MeccaConversationStatus,
  translate: ReturnType<typeof useTranslation>,
) => {
  return Object.values(enumType)
    .filter((v) => {
      return isNaN(Number(v));
    })
    .map((_status) => {
      return (
        <Select.Option key={_status} value={_status}>
          <StatusIndicator status={_status as ConversationStatus | MeccaConversationStatus} />{' '}
          {translate(_status)}
        </Select.Option>
      );
    });
};

const MessageStatusButton: React.FC<IProps> = ({
  id,
  status,
  loading,
  multiIds,
  ids,
  disabled,
}) => {
  const location = useLocation();
  const translate = useTranslation();
  const [value, setValue] = useState('');
  const { setShouldRefetchInbox } = useContext(InboxContext);

  const messageSubpageType = location.pathname.includes(callCenterKey)
    ? InboxSource.CallCenter
    : InboxSource.SocialNetworks;
  const [updateConversation, { loading: isLoading }] =
    useUpdateSingleConversation(messageSubpageType)();

  useEffect(() => {
    setValue(status);
  }, [status]);

  const onChangeStatus = async (_status: string) => {
    setValue(_status);
    if (isLoading) {
      return;
    }

    try {
      const elements = multiIds && ids ? ids : [id];

      const requests = elements.map((itemId: string) => {
        return updateConversation({
          variables: {
            id: itemId,
            input: { status: _status },
          },
        });
      });

      const awaitedResults = await Promise.allSettled(requests);

      const everythingSuccessful = awaitedResults.every((result) => {
        return result.status === 'fulfilled';
      });

      if (everythingSuccessful) {
        message.success(translate('conversationTypeSuccesfullyUpdated'));
      } else {
        message.error('Some messages were not updated');
      }

      setShouldRefetchInbox((prev: boolean) => {
        return !prev;
      });
    } catch (error) {
      if (isError(error)) {
        message.error(error.message);
      }
    }
  };

  const isSocialMediaPage = messageSubpageType === InboxSource.SocialNetworks;

  return (
    <div className={`message-status ${value}-status`}>
      <Select
        loading={loading && !disabled}
        onChange={onChangeStatus}
        value={value}
        disabled={!!disabled}
        getPopupContainer={() => {
          return document.querySelector('.message-status')!;
        }}
      >
        {getStatusOptions(
          ConversationStatus,
          translate,
        )}
      </Select>
    </div>
  );
};

export default MessageStatusButton;
