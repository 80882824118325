import React from 'react';
import './UserAvatar.scss';

interface IUserAvatar {
  firstName: string;
  lastName: string;
}

const UserAvatar: React.FC<IUserAvatar> = ({ firstName, lastName }) => {
  if (!firstName && !lastName) {
    return null;
  }

  const firstLetter = firstName.slice(0, 1);
  const secondLetter = lastName ? lastName.slice(0, 1) : firstName.slice(-1);

  return (
    <div className="user-avatar">{`${firstLetter.toUpperCase()}${secondLetter.toUpperCase()}`}</div>
  );
};

export default UserAvatar;
