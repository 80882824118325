import React, { FC, useState } from 'react';
import { InboxContext } from './inbox.context';

const InboxContextWrapper: FC = ({ children }) => {
  const [shouldRefetchInbox, setShouldRefetchInbox] = useState(false);
  const [isInboxLoading, setIsInboxLoading] = useState(false);

  return (
    <InboxContext.Provider
      value={{
        shouldRefetchInbox,
        setShouldRefetchInbox,
        inboxLoading: isInboxLoading,
        setInboxLoading: setIsInboxLoading,
      }}
    >
      {children}
    </InboxContext.Provider>
  );
};

export default InboxContextWrapper;
