import { format } from 'date-fns';

interface IFormattedDate {
  fullDate: string;
  hourMinutes: string;
}

export class DateUtils {
  static isToday(date: Date) {
    const today = new Date();

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  static formattedDate(date: Date, withTime = false) {
    if (withTime) {
      return `${DateUtils.getDateString(date)} - ${DateUtils.getTimeString(date)}`;
    }

    return DateUtils.getDateString(date);
  }

  static getDateString(date: Date) {
    return format(date, 'dd/MM/yyyy');
  }

  static getTimeString(date: Date) {
    return format(date, 'HH:mm');
  }
}

export const formattedDate = (d: string): IFormattedDate => {
  const date = new Date(d);

  function fullDate(): string {
    const month = date.toLocaleString('default', { month: 'long' });
    const dayName = date.toLocaleString('default', { weekday: 'long' });
    const dayNr = date.toLocaleString('default', { day: 'numeric' });
    const year = date.toLocaleString('default', { year: 'numeric' });

    return `${dayName} ${dayNr} ${month} ${year} `;
  }

  function hourMinutes(): string {
    return date.toTimeString().slice(0, 5);
  }

  return {
    fullDate: fullDate(),
    hourMinutes: hourMinutes(),
  };
};
