import { ApolloProvider } from '@apollo/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import 'antd/dist/antd.min.css';
import React from 'react';
import TranslateIntlProvider from 'translations/TranslateIntlProvider';
import MessageContextProvider from 'utils/context/MessagesContext';
import BugsnagWrapper from './components/Bugsnag/BugsnagWrapper';
import HotjarProvider from './components/HotjarProvider';
import MaintenanceMode from './components/MaintenanceMode';
import { client } from './graphql/client';
import keycloak from './keycloak';
import Fallback from './router/Fallback';
import MainRouter from './router/MainRouter';
import './styles/index.scss';

const App: React.FC = () => {
  const maintenanceModeIsOn = false;
  const productionDomain = 'app.memoweb.be';

  return (
    <BugsnagWrapper>
      <ReactKeycloakProvider
        authClient={keycloak}
        autoRefreshToken
        LoadingComponent={<Fallback />}
        initOptions={{
          // onLoad: 'login-required',
          onLoad: 'check-sso',
          adapter: 'default',
          flow: 'implicit',
        }}
        isLoadingCheck={(kc) => {
          return !!kc.userInfo && !!kc.profile;
        }}
        onEvent={(event, error) => {
          if (event === 'onReady' && !error) {
            keycloak.loadUserInfo();
            keycloak.loadUserProfile();
          }
        }}
      >
        <ApolloProvider client={client}>
          <TranslateIntlProvider>
            <HotjarProvider>
              {maintenanceModeIsOn && window.location.hostname.startsWith(productionDomain) ? (
                <MaintenanceMode />
              ) : (
                <MessageContextProvider>
                  <MainRouter />
                </MessageContextProvider>
              )}
            </HotjarProvider>
          </TranslateIntlProvider>
        </ApolloProvider>
      </ReactKeycloakProvider>
    </BugsnagWrapper>
  );
};

export default App;
