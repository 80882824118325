import React, { ComponentProps } from 'react';
import { Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Flatten } from '../../../types/general';
import './ExpandIcon.scss';

type IconType = Flatten<Parameters<Extract<ComponentProps<typeof Menu>['expandIcon'], Function>>>;

const ExpandIcon = ({ isOpen }: IconType) => {
  return (
    <RightOutlined
      id="custom-expand-icon"
      className={`custom-expand-icon ${isOpen ? 'custom-expand-icon__open' : ''}`}
    />
  );
};

export default ExpandIcon;
