import { useState } from 'react';
import { FetchResult } from '@apollo/client';
import { IObjectLiteral } from '../../../interfaces/object-literal.interface';
import {
  ChannelType,
  CreateMeccaConversationInputModel,
  CreateMeccaConversationMutation,
  useCreateMeccaConversationMutation,
  useForwardMeccaConversationsAgentMutation,
} from '../../../models/graphql';
import { useTranslation } from '../../../utils';
import { validateEmails, validatePhoneNumbers } from '../../../utils/global/helpers';
import { ISendMessageOptions, IStatusMessage } from './sendMessage.hook';

const noop = () => {
  return Promise.resolve({});
};

export const useCreateMessage = (
  { fileList, messageTxt, emails, phones }: ISendMessageOptions,
  getValues: () => Promise<IObjectLiteral> = noop,
) => {
  const [statusMessage, setStatusMessage] = useState<IStatusMessage>({
    status: 'init',
    message: '',
  });
  const translate = useTranslation();
  const [createConversation, { loading: createConversationLoading }] =
    useCreateMeccaConversationMutation();

  const [forwardConversationsAgent, { loading: forwardConversationAgentLoading }] =
    useForwardMeccaConversationsAgentMutation();

  const prepareCommonInput = (values: IObjectLiteral): CreateMeccaConversationInputModel => {
    const input: CreateMeccaConversationInputModel = {
      channelType: ChannelType.Blank,
      status: values.status,
      attachments: fileList,
      content: messageTxt,
      read: values.read,
      notify: false,
    };

    if (values.activeMessageType && Object.keys(values.activeMessageType).length) {
      const fieldValues = { ...values };
      delete fieldValues.read;
      delete fieldValues.status;
      delete fieldValues.activeMessageType;

      input.structuredType = {
        fieldValues: Object.entries(fieldValues).map(([label, value]) => {
          return {
            label,
            value,
          };
        }),
        name: values.activeMessageType.name,
      };
    }

    return input;
  };

  const sendMessageToMemoTech = () => {
    sendMessage(false, true);
  };

  const sendMessageToEmail = (): void => {
    const isValid = validateEmails(emails);

    if (!isValid) {
      return;
    }

    makeRequest({ emails }, true);
  };

  const sendMessageViaSMS = () => {
    const isValid = validatePhoneNumbers(phones);

    if (!isValid) {
      return;
    }

    makeRequest({ phones });
  };

  const sendMessage = (shouldSaveAttachments = false, answer = false) => {
    const additional: Partial<CreateMeccaConversationInputModel> = {};

    if (!shouldSaveAttachments) {
      additional.attachments = [];
    }

    if (answer) {
      additional.answer = answer;
    }

    makeRequest(additional);
  };

  const makeRequest = async (
    additionalData: Partial<CreateMeccaConversationInputModel> = {},
    isForwardViaEmail = false,
  ) => {
    const values = await getValues();

    if (!Object.keys(values).length) {
      return;
    }

    const input = prepareCommonInput(values);
    const result = await createConversation({
      context: {
        hasUpload: true,
      },
      variables: {
        input: {
          ...input,
          ...additionalData,
          emails: [],
        },
      },
    });

    const id = result?.data?.createMeccaConversation.id;

    if (isForwardViaEmail && id) {
      await forwardConversationsAgent({
        variables: {
          messagesIds: id,
          input: {
            emails: additionalData.emails,
            phones: [],
          },
        },
      });
    }

    validateResponse(result);
  };

  const validateResponse = (response: FetchResult<CreateMeccaConversationMutation>) => {
    if (response && response.data?.createMeccaConversation.id) {
      setStatusMessage({
        status: 'success',
        message: translate('createdSuccesfully'),
      });
    } else {
      setStatusMessage({
        status: 'error',
        message: translate('somethingWentWrong'),
      });
    }
  };

  return {
    statusMessage,
    sendMessage,
    sendMessageToEmail,
    sendMessageViaSMS,
    sendMessageToMemoTech,
    loading: createConversationLoading || forwardConversationAgentLoading,
  };
};
