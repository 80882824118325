import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Layout, Spin } from 'antd';
import { SideBarIcon, StorageUtils } from 'utils';
import SearchWebsite from '../../components/SearchWebsite/SearchWebsite';
import { ResponsiveView } from '../../components';
import UserStatus from './UserStatus/UserStatus';
import LanguagePicker from './LanguagePicker/LanguagePicker';
import './MainHeader.scss';
import CustomerPicker from './CustomerPicker/CustomerPicker';
import LogoutDropdown from './LogoutDropdown/LogoutDropdown';

interface IMainHeaderProps {
  collapsed: boolean;
  toggle: () => void;
}

const MainHeader: React.FC<IMainHeaderProps> = ({ collapsed, toggle }) => {
  const [customer, setCustomer] = useState<string>(StorageUtils.getCustomerId() as string);
  const isCustomerSet = customer && customer !== 'null';
  const { keycloak } = useKeycloak();

  return (
    <Layout.Header className="site-layout-background main-header">
      <ResponsiveView
        onMobile={
          <div className="main-header-left-container">
            <span
              className={`trigger${collapsed ? ' active' : ''}`}
              onClick={toggle}
              role="button"
              tabIndex={0}
            >
              <SideBarIcon />
            </span>
          </div>
        }
        onDesktop={
          <>
            <div className="main-header-left-container">
              <span
                className={`trigger${collapsed ? ' active' : ''}`}
                onClick={toggle}
                role="button"
                tabIndex={0}
              >
                <SideBarIcon />
              </span>
              <SearchWebsite />
              <CustomerPicker customerChangeCallback={undefined} />
            </div>
            <div className="main-header-right-container">
              {isCustomerSet ? <UserStatus /> : <Spin size="small" />}
              {isCustomerSet ? <LanguagePicker user={keycloak.profile} /> : <Spin size="small" />}
              <LogoutDropdown />
            </div>
          </>
        }
      />
    </Layout.Header>
  );
};

export default MainHeader;
