import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import { IntlProvider } from 'react-intl';
import { IWrapperProps } from 'utils';
import { appLanguageVar } from '../graphql/cache';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/de'); // Add locale data for de
}

const nlMsg = require('./messages/nl.json');
const enMsg = require('./messages/en.json');
const frMsg = require('./messages/fr.json');

const loadedMessages = {
  nl: nlMsg,
  en: enMsg,
  fr: frMsg,
};

type KeycloakTokenWithLocale = (Keycloak.KeycloakTokenParsed & { locale: string }) | undefined;

const TranslateIntlProvider: React.FC<IWrapperProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const language = useReactiveVar(appLanguageVar);

  useEffect(() => {
    if (!language) {
      appLanguageVar((keycloak.tokenParsed as KeycloakTokenWithLocale)?.locale || 'nl');
    }
  }, []);

  const currentLanguage = (language || 'nl') as keyof typeof loadedMessages;
  const messages = loadedMessages[currentLanguage];

  return (
    <IntlProvider
      onError={() => {
        return null;
      }}
      messages={messages}
      locale={currentLanguage}
      defaultLocale="nl"
    >
      {children}
    </IntlProvider>
  );
};

export default TranslateIntlProvider;
