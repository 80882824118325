import { BrowserOptions } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const backendHost = window.location.host.includes('localhost')
  ? `https://acc21.app.memoweb.memo.be`
  : `${window.location.protocol}//${window.location.host}`;

const dev = {
  FB_APP_ID: '368782804096190',
  SENTRY_DSN: 'https://ff71f7932bad45a49f2627bc0b9ad0ff@o407684.ingest.sentry.io/6012242',
  ENVIRONMENT: 'development',
  VIDEO_CALL_URL: 'https://dev.video.memoweb.aptus.be',
  SSO_URL: `${backendHost}/auth/realms/memoweb`,
  REALM_URI: '/auth/realms/memoweb',
  REALM: 'memoweb',
  KEYCLOAK_CLIENT_ID: 'memoweb-app',
  KEYCLOAK_URL: `${backendHost}/auth/`,
  API_URL: backendHost,
  SUB_URL: 'dev-memoweb-api.azurewebsites.net',
  WEBPUSH_PUBLIC_KEY:
    'BOO380Nz76IQ45zlr2glMpdts5VCPpnJWCtDf7ykTWmUD3t4D61AFmgj-jL5i6x271o3OqRChI_33q42tW98Zwk',
  BUGSNAG_API_KEY: '',
  HOTJAR_SITE_ID: 2882316,
  HOTJAR_VERSION: 6,
};

const stage = {
  FB_APP_ID: '498147387626924',
  SENTRY_DSN: 'https://ff71f7932bad45a49f2627bc0b9ad0ff@o407684.ingest.sentry.io/6012242',
  ENVIRONMENT: 'development',
  VIDEO_CALL_URL: 'https://acc.video.memoweb.aptus.be',
  SSO_URL: `${backendHost}/auth/realms/memoweb`,
  REALM_URI: '/auth/realms/memoweb',
  REALM: 'memoweb',
  KEYCLOAK_CLIENT_ID: 'memoweb-app',
  KEYCLOAK_URL: `${backendHost}/auth`,
  API_URL: `${backendHost}`,
  SUB_URL: 'acc-memoweb-api.azurewebsites.net',
  WEBPUSH_PUBLIC_KEY:
    'BK_o6Ummw6nDwr2YR5plMCeMJEve_bGI8rn52uc8ndmQC2M_jE66c_q2I6AU3Z5F3MARKxHBGcP2zbuTtmuwBBU',
  BUGSNAG_API_KEY: '',
  HOTJAR_SITE_ID: 2882316,
  HOTJAR_VERSION: 6,
};

const prod = {
  FB_APP_ID: '1563515753780549',
  SENTRY_DSN: 'https://ff71f7932bad45a49f2627bc0b9ad0ff@o407684.ingest.sentry.io/6012242',
  ENVIRONMENT: 'production',
  VIDEO_CALL_URL: 'https://video.memoweb.be',
  SSO_URL: `${backendHost}/auth/realms/memoweb`,
  API_URL: `${backendHost}`,
  REALM_URI: '/auth/realms/memoweb',
  REALM: 'memoweb',
  KEYCLOAK_CLIENT_ID: 'memoweb-app',
  KEYCLOAK_URL: `${backendHost}/auth`,
  SUB_URL: 'prd-memoweb-api.azurewebsites.net',
  WEBPUSH_PUBLIC_KEY:
    'BElhC3ZM1IMbPVAewW8IP8ONoVTkEoAWnsY_7lG7oFryLucTpDVzb5WrBfXiBG3xDnyATudGg-TNmIPUGX399aY',
  BUGSNAG_API_KEY: '5e57a9f7b203839455fdf51cbbe2a282',
  HOTJAR_SITE_ID: 3196070,
  HOTJAR_VERSION: 6,
};

const env = {
  dev,
  stage,
  prod,
};

type DevType = typeof dev;

export interface IEnvironment extends DevType {
  ENVIRONMENT: 'development' | 'production';
}

const envMode = (process.env.REACT_APP_ENVIRONMENT || 'prod') as keyof typeof env;
const environment = env[envMode] as IEnvironment;

export const sentryConfig: BrowserOptions = {
  environment: window.location.host,
  dsn: environment.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
};

export default environment;
