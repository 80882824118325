import React from 'react';
import { Form } from 'antd';
import { ConversationStructuredTypeField } from '../../models/graphql';
import { useTranslation } from '../../utils';
import { getStructuredFieldName, generateRules, renderInput } from './helpers';
import './StructuredTypeInput.scss';

interface IProps {
  field: ConversationStructuredTypeField;
  error: string[];
}

const StructuredTypeInput: React.FC<IProps> = ({ field, error }) => {
  const translate = useTranslation();
  const { defaultValue, label } = field;
  const name = getStructuredFieldName(field);

  return (
    <div className="generated-input">
      <Form.Item
        key={name}
        label={label.trim().toLowerCase()}
        name={name}
        rules={generateRules(field, translate)}
        initialValue={defaultValue}
        validateStatus={error && error.length ? 'error' : ''}
        help={error && error.length ? error : ''}
      >
        {renderInput(field)}
      </Form.Item>
    </div>
  );
};

export default StructuredTypeInput;
