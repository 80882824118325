import { IObjectLiteral } from 'interfaces/object-literal.interface';

export const parseQueryString = (queryString: string): IObjectLiteral => {
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  return pairs.reduce((acc, item) => {
    const [key, value] = item.split('=');

    return {
      ...acc,
      [decodeURIComponent(key)]: decodeURIComponent(value),
    };
  }, {});
};
