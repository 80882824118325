import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Form, Select, Spin } from 'antd';
import {
  ConversationStructuredTypeField,
  ConversationStructuredTypesQuery,
  useConversationStructuredTypesQuery,
} from '../../../models/graphql';
import { Logger, useTranslation } from '../../../utils';
import { IObjectLiteral } from '../../../interfaces/object-literal.interface';
import { getStatusOptions } from '../../MessageStatus/MessageStatus';
import { MeccaConversationStatus } from '../../../types/inbox';
import { getStructuredFieldName } from '../../StructuredTypeInput/helpers';
import StructuredTypeInput from '../../StructuredTypeInput/StructuredTypeInput';

interface INewMessageSectionProps {
  setGetValuesFunction: React.Dispatch<React.SetStateAction<() => Promise<IObjectLiteral>>>;
}

const NewMessageSection: React.FC<INewMessageSectionProps> = ({ setGetValuesFunction }) => {
  const [activeMessageType, setActiveMessageType] = useState<IObjectLiteral | undefined>({});
  const [messageSelectType, setMessageSelectType] = useState('');
  const { 1: setValidation } = useState(false);
  const [form] = Form.useForm();

  const getValues = useCallback(async () => {
    setValidation(true);

    const result = await form
      .validateFields()
      .catch((errors) => {
        Logger.error(errors.errorFields);
      })
      .finally(() => {
        setValidation(false);
      });

    return result ? { ...result, activeMessageType } : {};
  }, [activeMessageType]);

  const { data: conversationTypes, loading: conversationStructuredTypesLoading } =
    useConversationStructuredTypesQuery({});

  const messageTypes: ConversationStructuredTypesQuery['conversationStructuredTypes']['items'] =
    conversationTypes?.conversationStructuredTypes.items || [];
  const translate = useTranslation();

  useEffect(() => {
    setGetValuesFunction(() => {
      return getValues;
    });
  }, [activeMessageType]);

  useEffect(() => {
    if (messageTypes.length > 0) {
      const [type] = messageTypes;

      setMessageSelectType(type.name);
      setActiveMessageType({
        ...type,
        fields: type.fields.filter((field) => {
          return field.isEditableByCustomer;
        }),
      });
    } else {
      setActiveMessageType(undefined);
    }
  }, [messageTypes]);

  return (
    <div className="new-message">
      {conversationStructuredTypesLoading && (
        <div className="form-loader">
          <Spin size="large" />
        </div>
      )}
      {!conversationStructuredTypesLoading && (
        <Form form={form}>
          <div className="new-message__col">
            <Select
              value={messageSelectType || undefined}
              onChange={(key) => {
                setActiveMessageType(
                  messageTypes.find((messageType) => {
                    return messageType.name === key;
                  }),
                );
                setMessageSelectType(key);
              }}
            >
              {messageTypes.map((messageType) => {
                return (
                  <Select.Option key={messageType.name} value={messageType.name}>
                    {messageType.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div className="new-message__col">
            {activeMessageType?.fields?.map((field: ConversationStructuredTypeField) => {
              return (
                <StructuredTypeInput
                  key={getStructuredFieldName(field)}
                  error={form.getFieldError(getStructuredFieldName(field))}
                  field={field}
                />
              );
            })}
          </div>
          <div className="new-message__col">
            <div className="options">
              <Form.Item
                noStyle
                name="status"
                initialValue={MeccaConversationStatus.Open}
                rules={[{ required: true, message: translate('form.isRequired') }]}
              >
                <Select className="status">
                  {getStatusOptions(MeccaConversationStatus, translate)}
                </Select>
              </Form.Item>
              <Form.Item noStyle name="read" valuePropName="checked" initialValue={false}>
                <Checkbox>{translate('markAsUnread')}</Checkbox>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default NewMessageSection;
