import React, { ReactNode } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import environment from 'environment';

const apiKey = environment.BUGSNAG_API_KEY;

interface IOwnProps {
  children: ReactNode;
}

const BugsnagWrapper: React.FC<IOwnProps> = ({ children }) => {
  if (!apiKey) {
    return <>{children}</>;
  }

  Bugsnag.start({
    apiKey,
    plugins: [new BugsnagPluginReact()],
  });

  const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
  const ErrorBoundary = plugin.createErrorBoundary(React);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default BugsnagWrapper;
