import React, { useMemo } from 'react';
import { MeccaConversationModel, useGetMeccaConversationLazyQuery } from '../../../models/graphql';
import { ApolloLazyQueryKeyFromDataType } from '../../../types/apollo';
import { formattedDate, useTranslation } from '../../../utils';
import { formatHtmlMessage } from '../../../utils/global/helpers';
import { highlightPhoneNumbers } from '../../../utils/global/highlightPhoneNumbers';
import { htmlArrayToString } from '../../../utils/global/htmlArrayToString';

type MeccaConversationLazyResult = ApolloLazyQueryKeyFromDataType<
  typeof useGetMeccaConversationLazyQuery,
  'meccaConversation'
>;

const isMeccaConversationModel = (
  mecca?: MeccaConversationLazyResult,
): mecca is MeccaConversationModel => {
  const typenames = [
    'MeccaBlankConversationModel',
    'MeccaTextConversationModel',
    'MeccaVideoConversationModel',
    'MeccaConversationModel',
  ];
  // eslint-disable-next-line no-underscore-dangle
  return typenames.includes(mecca?.__typename || '');
};

const MessageContent: React.FC<{
  content?: MeccaConversationLazyResult;
}> = ({ content }) => {
  const translate = useTranslation();

  const formattedMessage = useMemo(() => {
    if (!isMeccaConversationModel(content) || !content?.htmlMessage) {
      return '';
    }

    const htmlMessage = highlightPhoneNumbers(content.htmlMessage);
    const stringMessage = htmlArrayToString(htmlMessage);

    return formatHtmlMessage(stringMessage, translate);
  }, [content]);

  if (!isMeccaConversationModel(content)) {
    return null;
  }

  return (
    <div className="message-content">
      <span
        dangerouslySetInnerHTML={{
          __html: formattedMessage,
        }}
      />
      <div className="message-body__dateTime">
        {content ? formattedDate(content?.createdAt).hourMinutes : translate('empty')}
      </div>
    </div>
  );
};

export default MessageContent;
