import React from 'react';
import { Loader } from 'components';

export default function Fallback() {
  return (
    <div className="router-lazy-spin">
      <Loader />
    </div>
  );
}
