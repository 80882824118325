import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import UserAvatar from '../UserAvatar';
import { useTranslation } from '../../../utils';
import './LogoutDropdown.scss';

const LogoutDropdown: React.FC = () => {
  const { keycloak } = useKeycloak();
  const translate = useTranslation();
  const logoutUser = () => {
    keycloak.logout();
  };

  return (
    <Dropdown
      overlay={() => {
        return (
          <Menu style={{ overflow: 'hidden' }}>
            <Menu.Item key="0" onClick={logoutUser}>
              {translate('form.logout')}
            </Menu.Item>
          </Menu>
        );
      }}
      trigger={['click']}
      placement="bottomLeft"
      className="user-dropdown"
    >
      <Button>
        <UserAvatar
          firstName={keycloak.profile?.firstName || ''}
          lastName={keycloak.profile?.lastName || ''}
        />
        {keycloak.profile?.firstName || (keycloak.profile?.email || '').split('@')[0]}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default LogoutDropdown;
