import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  MailOutlined,
  PoweroffOutlined,
  SearchOutlined,
  SettingOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Layout as AntdLayout, Spin, Tabs } from 'antd';
import LogoCollapsed from 'assets/images/logo-collapsed.svg';
import Logo from 'assets/images/logo.svg';
import { ADMIN_ROLES, getPermission, ParingIcon, StorageUtils, useTranslation } from 'utils';
import { useKeycloak } from '@react-keycloak/web';
import SearchWebsite from '../../components/SearchWebsite/SearchWebsite';
import CustomerPicker from '../MainHeader/CustomerPicker/CustomerPicker';
import LanguagePicker from '../MainHeader/LanguagePicker/LanguagePicker';
import UserStatus from '../MainHeader/UserStatus/UserStatus';
import SidebarMenu from './SidebarMenu';
import './Sidebar.scss';
import LogoutDropdown from '../MainHeader/LogoutDropdown/LogoutDropdown';
import { ResponsiveView } from '../../components';

interface ISidebarProps {
  collapsed: boolean;
}

const { Sider } = AntdLayout;

const Sidebar: React.FC<ISidebarProps> = ({ collapsed }) => {
  const translate = useTranslation();
  const LogoIcon = collapsed ? LogoCollapsed : Logo;
  const [customer, setCustomer] = useState<string>(StorageUtils.getCustomerId() as string);
  const isCustomerSet = customer && customer !== 'null';
  const { keycloak } = useKeycloak();

  return (
    <div className={`sidebar-container${collapsed ? ' sidebar-collapsed' : ''}`}>
      <div className="sidebar-content">
        <Sider trigger={null} collapsible collapsed={collapsed} theme="light" width="263px">
          <div>
            <div className="sidebar-logo">
              <NavLink to="/">
                <div className={`sidebar-logo-content${collapsed ? '--collapsed' : ''}`}>
                  <img src={LogoIcon} alt="sidebar-logo" />
                </div>
              </NavLink>
            </div>
            <ResponsiveView
              onMobile={
                <Tabs type="card" defaultActiveKey="1" className="mobile-tabs">
                  <Tabs.TabPane tab={<SearchOutlined />} key="1">
                    <SearchWebsite />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<UserSwitchOutlined />} key="2">
                    <CustomerPicker customerChangeCallback={undefined} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<SettingOutlined />} key="3">
                    <div className="settings-container">
                      {isCustomerSet ? <UserStatus /> : <Spin size="small" />}
                      {isCustomerSet ? (
                        <LanguagePicker user={keycloak.profile} />
                      ) : (
                        <Spin size="small" />
                      )}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<PoweroffOutlined />} key="4">
                    <LogoutDropdown />
                  </Tabs.TabPane>
                </Tabs>
              }
            />
            <SidebarMenu collapsed={collapsed} />
          </div>
          <div className="sidebar-bottom-container">
            <div className="sidebar-bottom-container__links">
              {getPermission(ADMIN_ROLES) && (
                <Link
                  className={!collapsed ? 'contact-button' : 'contact-button--collapsed'}
                  to="/settings/connections/social"
                >
                  <ParingIcon />
                  {collapsed ? null : <span>{translate('pairing')}</span>}
                </Link>
              )}
              <Link
                className={!collapsed ? 'contact-button' : 'contact-button--collapsed'}
                to="/contact"
              >
                <MailOutlined />
                {collapsed ? null : <span>{translate('contact')}</span>}
              </Link>
            </div>
            {!collapsed && (
              <div className="legal-info">
                <div>
                  &copy; {new Date().getFullYear()} - <a href="https://www.memo.be/">Memo NV</a>
                </div>
                <ul className="legal-info__pages">
                  <li>
                    <a
                      href="https://assets-global.website-files.com/6568a10e619d90f7141a52c5/6568a10e619d90f7141a544a_NL_Algemene_voorwaarden.pdf"
                      target="_blank"
                      rel="nooperner noreferrer"
                    >
                      {translate('termsandconditions')}
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy-policy">{translate('privacy')}</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Sider>
      </div>
    </div>
  );
};

export default Sidebar;
