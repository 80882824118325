import { useEffect, useState } from 'react';

const useNotificationNumberTabTitle = () => {
  const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        clearInterval(intervalId!);
        setMessageCount(0);
        intervalId = null;
        document.title = 'MemoWeb';
      } else if (!intervalId && messageCount > 0) {
        intervalId = setInterval(() => {
          document.title =
            document.title === 'MemoWeb' ? `You have ${messageCount} new messages` : 'MemoWeb';
        }, 1000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId!);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [messageCount]);

  return setMessageCount;
};

export default useNotificationNumberTabTitle;
