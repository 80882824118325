import { makeVar } from '@apollo/client';

const currentDate = new Date();

export const yearFilter = makeVar<number>(currentDate.getFullYear());
export const monthFilter = makeVar<number>(currentDate.getMonth());

export const appLanguageVar = makeVar<string>('');

export const inboxFilterVar = makeVar<{ [key: string]: string }>({});

export const menuCallCenterBadgeCounterVar = makeVar('0');
export const menuSocialMediaBadgeCounterVar = makeVar('0');

export const menuInboxBadgeCounterVar = {
  callCenter: menuCallCenterBadgeCounterVar,
  socialMedia: menuSocialMediaBadgeCounterVar,
};
