export enum MessagesSource {
  Inbox = 'Inbox',
  FollowUp = 'FollowUp',
  Archive = 'Archive',
  Assigned = 'Assigned',
}

export enum InboxSource {
  CallCenter = 'call-center',
  SocialNetworks = 'social-networks',
}

export enum MeccaConversationStatus {
  Callback = 'CALLBACK',
  Open = 'OPEN',
  Urgent = 'URGENT',
  Important = 'IMPORTANT',
  High = 'HIGH',
  CallbackHimself = 'CALLBACK_HIMSELF',
  EmailBack = 'EMAIL_BACK',
}
