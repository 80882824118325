import React from 'react';
import { Button } from 'components';
import { ButtonTypes } from 'utils';

interface IFooterProps {
  cancelText?: string;
  onCloseModal?: () => void;
  okText?: string;
  onOk?: (() => void) | ((values: { name: string }) => void);
  form?: string;
  loading?: boolean;
}

export const getModalFooter = ({
  cancelText,
  onCloseModal,
  onOk,
  loading,
  form,
  okText = 'OK',
}: IFooterProps) => {
  return [
    cancelText && onCloseModal && (
      <Button key="cancel" text={cancelText} type={ButtonTypes.White} onClick={onCloseModal} />
    ),
    onOk && (
      <Button
        key="submit"
        htmlType="submit"
        form={form}
        text={okText}
        type={ButtonTypes.Blue}
        onClick={onOk}
        loading={loading}
      />
    ),
  ];
};
