import * as React from 'react';
import { useHistory } from 'react-router-dom';
import defaultPush from 'push.js';
import { notification } from 'antd';
import { gql, useSubscription } from '@apollo/client';
import { useTranslation } from 'utils';
import { Icon } from 'components';
import { useUserStatusUpdated } from 'graphql/hooks/useGetUserStatusSubscription';
import './VideoNotification.scss';

const ringtone = new Audio('/sound/ringtone.mp3');

ringtone.loop = true;

const NOTIFY_SUBSCRIPTION = gql`
  subscription callNotification {
    callNotification {
      callId
      customerId
      removeCall
    }
  }
`;

const VideoNotification = () => {
  const history = useHistory();
  const translate = useTranslation();
  const { userStatus } = useUserStatusUpdated();
  const { data } = useSubscription(NOTIFY_SUBSCRIPTION);

  let calls: string[] = [];
  const filteredCalls = (_callId: string) => {
    return calls.filter((call) => {
      return call !== _callId;
    });
  };

  const closeCall = (callId: string) => {
    notification.close(callId);
    calls = filteredCalls(callId);

    ringtone.pause();
    ringtone.currentTime = 0;
  };

  const takeCall = (callId: string) => {
    history.push({
      pathname: `/call/${callId}`,
      search: `?from=${history.location.pathname}`,
    });
    calls = filteredCalls(callId);
    ringtone.pause();
    ringtone.currentTime = 0;

    notification.close(callId);
  };

  if (!userStatus) {
    return null;
  }

  const Push = defaultPush;

  const renderSubscription = () => {
    if (data) {
      const { callNotification } = data;
      if (callNotification && callNotification.callId) {
        const match = calls.find((call) => {
          return call === callNotification.callId;
        });

        if (callNotification.removeCall) {
          Push.close(callNotification.callId);
          notification.close(callNotification.callId);

          calls = filteredCalls(callNotification.callId);

          if (!calls.length) {
            ringtone.pause();
            ringtone.currentTime = 0;
          }
        }

        if (!match && !callNotification.removeCall) {
          calls.push(callNotification.callId);

          ringtone.play();

          Push.create('Je wordt gebeld', {
            tag: callNotification.callId,
            requireInteraction: true,
            timeout: 100000, // 100 sec. until the notification closes automatically
            onClick() {
              window.focus();
              if (callNotification.callId) {
                takeCall(callNotification.callId);
              }
            },
          });

          notification.info({
            message: translate('incoming.call'),
            description: (
              <div className="video-notification">
                <span
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    return callNotification.callId && takeCall(callNotification.callId);
                  }}
                  className="take-call"
                >
                  <Icon name="takeCall" />
                  {translate('record')}
                </span>
                <span
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    return callNotification.callId && closeCall(callNotification.callId);
                  }}
                  className="reject-call"
                >
                  <Icon name="rejectCall" />
                  {translate('ignore')}
                </span>
              </div>
            ),
            duration: null,
            key: callNotification.callId,
          });
        }
      }
    }

    return null;
  };

  return <div className="notification-wrapper">{renderSubscription()}</div>;
};

export default VideoNotification;
