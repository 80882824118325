import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { IRoutesProps } from 'utils';
import './PageHeaderTabs.scss';
import { IsMobileContext } from '../../Layout/Layout';

const { TabPane } = Tabs;

interface IPageHeaderTabsTabsProps {
  routes: IRoutesProps[];
}

const PageHeaderTabs: React.FC<IPageHeaderTabsTabsProps> = ({ routes }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { type } = useParams() as { type: string };
  const isMobile = useContext(IsMobileContext);

  const handleTabClick = useCallback(
    (key: string): void => {
      history.push(key);
    },
    [history],
  );

  const renderTabs = (tabs: IRoutesProps[]): React.ReactNode => {
    return tabs.map(({ name, path, icon }) => {
      return (
        <TabPane
          tab={
            <span>
              {icon}
              {!isMobile ? name : ''}
            </span>
          }
          key={path}
        />
      );
    });
  };

  return (
    <Tabs
      activeKey={type === 'temporary' ? '/customer/instructions/temporary' : pathname}
      onChange={handleTabClick}
      className="page-header-tabs"
    >
      {renderTabs(routes)}
    </Tabs>
  );
};

export default PageHeaderTabs;
