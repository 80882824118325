import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';

export class GraphQLDocuments {
  /**
   * SOCIAL NETWORK CONVERSATIONS
   */
  public static getSocialConversationPreviews: DocumentNode = loader(
    '../../graphql/documents/conversations/socials/getConversationsPreview.graphql',
  );

  public static getSocialConversation: DocumentNode = loader(
    '../../graphql/documents/conversations/socials/getConversation.graphql',
  );

  public static socialConversationUpdate: DocumentNode = loader(
    '../../graphql/documents/conversations/socials/conversationUpdated.graphql',
  );

  public static socialConversationBulkUpdate: DocumentNode = loader(
    '../../graphql/documents/conversations/socials/conversationBulkUpdated.graphql',
  );

  /**
   * CALL CENTER CONVERSATIONS
   */
  public static getMeccaConversationPreviews: DocumentNode = loader(
    '../../graphql/documents/conversations/call-center/getConversationsPreview.graphql',
  );

  public static getMeccaConversation: DocumentNode = loader(
    '../../graphql/documents/conversations/call-center/getConversation.graphql',
  );

  public static meccaConversationUpdate: DocumentNode = loader(
    '../../graphql/documents/conversations/call-center/conversationUpdated.graphql',
  );

  public static meccaConversationBulkUpdate: DocumentNode = loader(
    '../../graphql/documents/conversations/call-center/conversationBulkUpdated.graphql',
  );

  // public static getConversationPreviews: DocumentNode = loader("../../graphql/documents/conversations/getConversationPreviews.graphql");
  // public static getRelatedConversations: DocumentNode = loader("../../graphql/documents/conversations/getRelatedConversations.graphql");
  // public static getUnreadConversationsCount: DocumentNode = loader("../../graphql/documents/conversations/getUnreadConversationsCount.graphql");
  // public static updateConversation: DocumentNode = loader("../../graphql/documents/conversations/updateConversation.graphql");
  // public static sendConversationText: DocumentNode = loader("../../graphql/documents/conversations/sendConversationText.graphql");
  // public static conversationPreviewUpdated: DocumentNode = loader("../../graphql/documents/conversations/conversationPreviewUpdated.graphql");
  // public static conversationDetailUpdated: DocumentNode = loader("../../graphql/documents/conversations/conversationDetailUpdated.graphql");
  // public static conversationCreated: DocumentNode = loader("../../graphql/documents/conversations/conversationCreated.graphql");
  // public static conversationTextAdded: DocumentNode = loader("../../graphql/documents/conversations/conversationTextAdded.graphql");
  // public static getConversation: DocumentNode = loader("../../graphql/documents/conversations/getConversation.graphql");
  // public static createConversation: DocumentNode = loader("../../graphql/documents/conversations/createConversation.graphql");
  public static conversationStructuredTypes: DocumentNode = loader(
    '../../graphql/documents/conversations/conversationStructuredTypes.graphql',
  );
  // public static addConversationAgentMessage: DocumentNode = loader("../../graphql/documents/conversations/addConversationAgentMessage.graphql");
  // public static forwardConversationAgentMessage: DocumentNode = loader("../../graphql/documents/conversations/forwardConversationAgentMessage.graphql");
  // public static fornwardConversationsAgent: DocumentNode = loader("../../graphql/documents/conversations/forwardConversationsAgent.graphql");

  public static getCustomerAttachments: DocumentNode = loader(
    '../../graphql/documents/attachments/getCustomerAttachments.graphql',
  );

  public static createCustomerAttachment: DocumentNode = loader(
    '../../graphql/documents/attachments/createCustomerAttachment.graphql',
  );

  public static deleteCustomerAttachment: DocumentNode = loader(
    '../../graphql/documents/attachments/deleteCustomerAttachment.graphql',
  );

  public static updateCustomerAttachment: DocumentNode = loader(
    '../../graphql/documents/attachments/updateCustomerAttachment.graphql',
  );

  public static sendContactRequest: DocumentNode = loader(
    '../../graphql/documents/contact/sendContactRequest.graphql',
  );

  public static getPairedChannels: DocumentNode = loader(
    '../../graphql/documents/pairing/getPairedChannels.graphql',
  );

  public static pairChannel: DocumentNode = loader(
    '../../graphql/documents/pairing/pairChannel.graphql',
  );

  public static unpairChannel: DocumentNode = loader(
    '../../graphql/documents/pairing/unpairChannel.graphql',
  );

  public static activateChannel: DocumentNode = loader(
    '../../graphql/documents/pairing/activateChannel.graphql',
  );

  public static deactivateChannel: DocumentNode = loader(
    '../../graphql/documents/pairing/deactivateChannel.graphql',
  );

  public static updateChannel: DocumentNode = loader(
    '../../graphql/documents/pairing/updateChannel.graphql',
  );

  public static getCountries: DocumentNode = loader(
    '../../graphql/documents/users/getCountries.graphql',
  );

  public static getCustomers: DocumentNode = loader(
    '../../graphql/documents/users/getCustomers.graphql',
  );

  public static getCustomersWithInfo: DocumentNode = loader(
    '../../graphql/documents/users/getCustomersWithInfo.graphql',
  );

  public static getUsers: DocumentNode = loader('../../graphql/documents/users/getUsers.graphql');

  public static getUser: DocumentNode = loader('../../graphql/documents/users/getUser.graphql');

  public static updateCustomer: DocumentNode = loader(
    '../../graphql/documents/users/updateCustomer.graphql',
  );

  public static getUserStatus: DocumentNode = loader(
    '../../graphql/documents/users/getUserStatus.graphql',
  );

  public static updateUserStatus: DocumentNode = loader(
    '../../graphql/documents/users/updateUserStatus.graphql',
  );

  public static userStatusUpdated: DocumentNode = loader(
    '../../graphql/documents/users/userStatusUpdated.graphql',
  );

  public static createUser: DocumentNode = loader(
    '../../graphql/documents/users/createUser.graphql',
  );

  public static updateUser: DocumentNode = loader(
    '../../graphql/documents/users/updateUser.graphql',
  );

  public static deleteUser: DocumentNode = loader(
    '../../graphql/documents/users/deleteUser.graphql',
  );

  public static getDashboard: DocumentNode = loader(
    '../../graphql/documents/dashboard/getDashboard.graphql',
  );

  public static getDashboardNotifications: DocumentNode = loader(
    '../../graphql/documents/dashboard/getDashboardNotifications.graphql',
  );

  public static incomingCalls: DocumentNode = loader(
    '../../graphql/documents/reports/incomingCalls.graphql',
  );

  public static outgoingCalls: DocumentNode = loader(
    '../../graphql/documents/reports/outgoingCalls.graphql',
  );

  public static downloadIncomingCallReporting: DocumentNode = loader(
    '../../graphql/documents/reports/downloadIncomingCallReporting.graphql',
  );

  public static downloadOutgoingCallReporting: DocumentNode = loader(
    '../../graphql/documents/reports/downloadOutgoingCallReporting.graphql',
  );

  public static videoCallReporting: DocumentNode = loader(
    '../../graphql/documents/reports/videoCallReporting.graphql',
  );

  public static downloadVideoCallReporting: DocumentNode = loader(
    '../../graphql/documents/reports/downloadVideoCallReporting.graphql',
  );

  public static getCustomerCrmContacts: DocumentNode = loader(
    '../../graphql/documents/crmContacts/getCustomerCrmContacts.graphql',
  );

  public static updateCustomerCrmContact: DocumentNode = loader(
    '../../graphql/documents/crmContacts/updateCustomerCrmContact.graphql',
  );

  public static deleteCustomerCrmContact: DocumentNode = loader(
    '../../graphql/documents/crmContacts/deleteCustomerCrmContact.graphql',
  );

  public static createCustomerCrmContact: DocumentNode = loader(
    '../../graphql/documents/crmContacts/createCustomerCrmContact.graphql',
  );

  public static getCustomerCrmContactCommunicationTypes: DocumentNode = loader(
    '../../graphql/documents/crmContacts/getCustomerCrmContactCommunicationTypes.graphql',
  );

  public static getCustomerCrmContactGroups: DocumentNode = loader(
    '../../graphql/documents/crmContacts/getCustomerCrmContactGroups.graphql',
  );

  public static getCustomerFixedInstructions: DocumentNode = loader(
    '../../graphql/documents/instructions/getCustomerFixedInstructions.graphql',
  );

  public static getCustomerTemporaryInstructions: DocumentNode = loader(
    '../../graphql/documents/instructions/getCustomerTemporaryInstructions.graphql',
  );

  public static deleteCustomerTemporaryInstruction: DocumentNode = loader(
    '../../graphql/documents/instructions/deleteCustomerTemporaryInstruction.graphql',
  );

  public static updateCustomerTemporaryInstruction: DocumentNode = loader(
    '../../graphql/documents/instructions/updateCustomerTemporaryInstruction.graphql',
  );

  public static createCustomerTemporaryInstruction: DocumentNode = loader(
    '../../graphql/documents/instructions/createCustomerTemporaryInstruction.graphql',
  );

  public static getInvoices: DocumentNode = loader(
    '../../graphql/documents/invoices/getInvoices.graphql',
  );

  public static payInvoice: DocumentNode = loader(
    '../../graphql/documents/invoices/payInvoice.graphql',
  );

  public static videoCallSettingsUpdated: DocumentNode = loader(
    '../../graphql/documents/video/updateSettingsSubscription.graphql',
  );

  public static answerVideoCall: DocumentNode = loader(
    '../../graphql/documents/video/answerVideoCall.graphql',
  );

  public static endVideoCall: DocumentNode = loader(
    '../../graphql/documents/video/endVideoCall.graphql',
  );

  public static callNotification: DocumentNode = loader(
    '../../graphql/documents/video/callNotification.graphql',
  );

  public static getDefaultLocale: DocumentNode = loader(
    '../../graphql/documents/video/getDefaultLocale.graphql',
  );

  public static getVideoCallSettings: DocumentNode = loader(
    '../../graphql/documents/video/getVideoCallSettings.graphql',
  );

  public static createVideoCallSettings: DocumentNode = loader(
    '../../graphql/documents/video/createVideoCallSettings.graphql',
  );

  public static updateVideoCallSettings: DocumentNode = loader(
    '../../graphql/documents/video/updateVideoCallSettings.graphql',
  );

  public static getVideoCalls: DocumentNode = loader(
    '../../graphql/documents/video/getVideoCalls.graphql',
  );

  public static search: DocumentNode = loader('../../graphql/documents/search/search.graphql');

  public static getTheme: DocumentNode = loader('../../graphql/documents/theme/getTheme.graphql');

  public static getStandby: DocumentNode = loader(
    '../../graphql/documents/standby/getStandby.graphql',
  );

  public static createStandby: DocumentNode = loader(
    '../../graphql/documents/standby/createStandby.graphql',
  );

  public static deleteStandby: DocumentNode = loader(
    '../../graphql/documents/standby/deleteStandby.graphql',
  );

  public static updateStandby: DocumentNode = loader(
    '../../graphql/documents/standby/updateStandby.graphql',
  );

  public static registerWebpush: DocumentNode = loader(
    '../../graphql/documents/webpush/registerWebpush.graphql',
  );
}
