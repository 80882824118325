import { Maybe } from '../../models/graphql';
import { useTranslation } from '../hooks/useTranslation';
import { DATE_REGEX } from './regex';
import { ValidationUtils } from './validationUtils';

export const formatHtmlMessage = (
  htmlMessage: string | Maybe<string> | undefined,
  translate: ReturnType<typeof useTranslation>,
  additionalMarkUp?: boolean,
): string => {
  if (!htmlMessage) {
    return translate('empty');
  }

  const lines = htmlMessage.split('<br />');

  return lines
    .reduce((newHtmlMessage, line) => {
      const lineParts = line.split(':');
      let finalPart = line;

      if (lineParts.length === 2) {
        const [key, value] = lineParts;
        const notDateRegex = !DATE_REGEX.test(key);

        // The search is used so the <a> doesn't get <strong> inserted into it's href.
        if (notDateRegex && value.search('clickable-phone-number') === -1) {
          finalPart = `${key}: <strong>${value}</strong>`;
        }
      }

      if (additionalMarkUp) {
        return `${newHtmlMessage}<p>${finalPart}</p>`;
      }

      return `${newHtmlMessage}<br />${finalPart}`;
    }, '')
    .replace('<br />', '');
};

export const validateEmails = (values: string[]) => {
  return (
    values.every((value) => {
      return ValidationUtils.isValidEmail({ value });
    }) || 'containsInvalidEmail'
  );
};

export const validatePhoneNumbers = (values: string[]) => {
  return (
    values.every((value) => {
      return ValidationUtils.isValidPhonenumber({ value, minLength: 10 });
    }) || 'containsInvalidPhoneNumber'
  );
};
