import React, { useContext, useEffect, useState } from 'react';
import { Button, Collapse, Input, message, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { UploadProps } from 'antd/lib/upload/interface';
import { useGetCustomerCrmContacts } from '../../graphql/hooks/useGetCustomerCrmContacts';
import { IObjectLiteral } from '../../interfaces/object-literal.interface';
import { useGetMeccaConversationLazyQuery } from '../../models/graphql';
import { ApolloLazyQueryKeyFromDataType } from '../../types/apollo';
import { AttachmentIcon, useTranslation } from '../../utils';
import { InboxContext } from '../../utils/context/InboxContext/inbox.context';
import CrmContactSelector, { CrmInformationType } from '../CrmContactSelector/CrmContactSelector';
import MessageContent from './components/MessageContent';
import NewMessageSection from './components/NewMessageSection';
import { useChooseHandlers } from './hooks/chooseHandlers.hook';
import './MessageAnswer.scss';
import '../../pages/InboxPage/AttachmentView/AttachmentsView.scss';
import { DeleteOutlined } from '@ant-design/icons';
import AttachmentPreview from '../../Layout/AttachmentPreview/AttachmentPreview';

interface IMessageAnswerProps {
  messageId?: string;
  content?: ApolloLazyQueryKeyFromDataType<
    typeof useGetMeccaConversationLazyQuery,
    'meccaConversation'
  >;
  isNewMessage?: boolean;
  closeModal?: () => void;
}

const noop = () => {
  return (): Promise<IObjectLiteral> => {
    return Promise.resolve({});
  };
};

const MessageAnswer: React.FC<IMessageAnswerProps> = ({
  messageId,
  content,
  isNewMessage = false,
  closeModal = () => {},
}) => {
  const [messageTxt, setMessageTxt] = useState('');
  const [getValuesFunction, setGetValuesFunction] = useState(noop);
  const [fileList, setFileList] = useState<Array<RcFile>>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [phones, setPhones] = useState<string[]>([]);
  const { setShouldRefetchInbox } = useContext(InboxContext);
  const translate = useTranslation();
  const { Panel } = Collapse;

  const { customerCrmContacts } = useGetCustomerCrmContacts();

  const {
    statusMessage,
    sendMessage,
    sendMessageViaSMS,
    sendMessageToEmail,
    sendMessageToMemoTech,
    loading: sendMessageLoading,
  } = useChooseHandlers({
    isNewMessage,
    getValues: getValuesFunction,
    options: {
      fileList,
      phones,
      emails,
      messageId,
      messageTxt,
    },
  });

  useEffect(() => {
    const { status, message: resultMessage } = statusMessage;
    if (status !== 'init') {
      message[status](resultMessage);
    }

    if (status === 'success') {
      setEmails([]);
      setPhones([]);
      setFileList([]);
      setMessageTxt('');
      closeModal();
      setShouldRefetchInbox((prev) => {
        return !prev;
      });
    }
  }, [statusMessage]);

  const uploadProps: UploadProps = {
    beforeUpload: (file: RcFile) => {
      setFileList((prev) => {
        return [...prev, file];
      });

      return false;
    },
    onRemove: (file: UploadFile) => {
      const newFileList = fileList.filter((listItem) => {
        return listItem.uid !== file.uid;
      });

      setFileList(newFileList);
    },
    itemRender(originNode, file, listOfFiles, actions) {
      const url = URL.createObjectURL(file.originFileObj);
      return (
        <Collapse className="ant-upload-list-text-container">
          <Panel
            header={<div className="ant-collapse-header-title">{file?.originFileObj?.name}</div>}
            key={file.uid}
            extra={
              <Button
                type="link"
                icon={<DeleteOutlined/>}
                style={{color: 'grey'}}
                onClick={actions.remove}
              />
            }
          >
            <AttachmentPreview url={url} fileName={file?.originFileObj?.name}/>
          </Panel>
        </Collapse>
      );
    }
  };

  const onMessageHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const messageValue = event.target.value;

    setMessageTxt(messageValue);
  };

  return (
    <div className="message-answer">
      {!isNewMessage && <MessageContent content={content} />}
      {isNewMessage && <NewMessageSection setGetValuesFunction={setGetValuesFunction} />}
      <div className="message-answer__attachment">
        <Upload {...uploadProps} className="mb-2">
          <AttachmentIcon />
          <span className="attachment-text">{translate('attachments')}</span>
        </Upload>
      </div>
      <div className="message-answer__message">
        <label htmlFor="message">{translate('newConversation.message').concat(':')}</label>
        <Input.TextArea value={messageTxt} onChange={onMessageHandler} name="message" />
      </div>
      <div className="message-answer__actions">
        <div className="message-answer__actions-send__email">
          <CrmContactSelector
            value={emails}
            setValue={setEmails}
            informationType={CrmInformationType.Email}
            crmContacts={customerCrmContacts}
            placeholder="EMAIL"
            buttonProps={{
              text: translate('newConversation.emails'),
              loading: sendMessageLoading,
              onClick: () => {
                sendMessageToEmail();
              },
            }}
          />
        </div>

        <div className="message-answer__actions-send__sms">
          <CrmContactSelector
            value={phones}
            setValue={setPhones}
            informationType={CrmInformationType.Phonenumber}
            crmContacts={customerCrmContacts}
            placeholder="standby.SMS"
            buttonProps={{
              text: translate('newConversation.phonenumbers'),
              loading: sendMessageLoading,
              onClick: () => {
                sendMessageViaSMS();
              },
            }}
          />
        </div>
        <div className="message-answer__actions-send__memotech">
          <Button
            loading={sendMessageLoading}
            onClick={() => {
              sendMessageToMemoTech();
            }}
          >
            {translate('newConversation.sendToMecca')}
          </Button>
        </div>

        <div className="message-answer__actions-save">
          <Button
            loading={sendMessageLoading}
            onClick={() => {
              sendMessage(true);
            }}
          >
            {translate('save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessageAnswer;
