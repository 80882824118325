import { DayNightIcon } from 'components';
import { iconMapper } from 'components/DayNightIcon/DayNightIcon';
import { DayNightCallTypes } from 'models/graphql';
import { ICalls } from 'types/calls';
import { formattedDate, secondsToMinutesOrHours, useTranslation } from 'utils';
import { capitalizeString } from 'utils/global/capitalizeString';
import { convertToBelgianDate } from '../global/convertToBelgianDate';

export function CallsColumns() {
  const translate = useTranslation();
  return [
    {
      title: translate('date'),
      dataIndex: 'callDateTime',
      key: 'callDateTime',
      render: (record: string) => {
        return <span data-title={`${translate('date')}: `}>{formattedDate(record).fullDate}</span>;
      },
      sorter: {
        compare: (a: ICalls, b: ICalls) => {
          return a.callDateTime.localeCompare(b.callDateTime);
        },
        multiple: 2,
      },
    },
    {
      title: translate('time'),
      dataIndex: 'callDateTime',
      key: 'time',
      render: (record: string) => {
        return (
          <div data-title={`${translate('time')}: `}>{convertToBelgianDate(record, 'HH:mm')}</div>
        );
      },
      sorter: {
        compare: (a: ICalls, b: ICalls) => {
          return a.callDateTime.localeCompare(b.callDateTime);
        },
        multiple: 2,
      },
    },
    {
      title: translate('reports.duration'),
      dataIndex: 'callDuration',
      key: 'callDuration',
      render: (record: number) => {
        return (
          <div data-title={`${translate('reports.duration')}: `}>
            {secondsToMinutesOrHours(record, false, true, true)}
          </div>
        );
      },
      sorter: {
        compare: (a: ICalls, b: ICalls) => {
          return Number(a.callDuration) - Number(b.callDuration);
        },
        multiple: 2,
      },
    },
    {
      title: translate('reports.phoneNumber'),
      dataIndex: 'caller',
      key: 'caller',
      sorter: {
        compare: (a: ICalls, b: ICalls) => {
          return a.caller.localeCompare(b.caller);
        },
        multiple: 2,
      },
      render: (record: string) => {
        return <div data-title={`${translate('reports.phoneNumber')}: `}>{record}</div>;
      },
    },
    {
      title: translate('tariff'),
      dataIndex: 'dayNight',
      key: 'type',
      render: (record: DayNightCallTypes) => {
        return (
          <span data-title={`${translate('tariff')}: `} className={`tarif-${iconMapper[record]}`}>
            <DayNightIcon val={record} />
            {capitalizeString(translate(`reports.${record}`))}
          </span>
        );
      },
      sorter: {
        compare: (a: ICalls, b: ICalls) => {
          return a.dayNight.localeCompare(b.dayNight);
        },
        multiple: 2,
      },
    },
  ];
}

export default CallsColumns;
