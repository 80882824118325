import { ApolloError } from '@apollo/client';
import { useTranslation } from '../hooks/useTranslation';

export const parseError = (
  error: ApolloError,
  translate: ReturnType<typeof useTranslation>,
): string => {
  const match = error.message.match(/error\..*$/);

  if (match && match.length) {
    return translate(match[0].toLowerCase());
  }

  return translate('error.unknown');
};
