export const AVAILABLE_ROLES = {
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin',
  AGENT: 'agent',
};

export const ADMIN_ROLES = [AVAILABLE_ROLES.ADMIN, AVAILABLE_ROLES.SUPERADMIN];

export const QUERY_BASED_ROLES = {
  CAN_ACCESS_CALENDAR: 'calendar',
};
