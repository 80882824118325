import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { Customer, SortOrder, useGetCustomersQuery } from '../../../models/graphql';
import { parseQueryString, StorageUtils } from '../../../utils';
import './CustomerPicker.scss';
import { useApolloClient } from '@apollo/client';

interface ILocation {
  search: string;
  filter: string;
  pathname: string;
}

interface IOwnProps {
  customerChangeCallback: undefined | ((customerId: string, customerName: string) => void);
}

const { Option } = Select;

const CustomerPicker: FC<IOwnProps> = ({ customerChangeCallback }) => {
  const [customerId, setCustomerId] = useState<string>(StorageUtils.getCustomerId() as string);
  const [customerName, setCustomerName] = useState<string>(
    StorageUtils.getCustomerName() as string,
  );
  const [customerObject, setCustomerObject] = useState<Customer | null | undefined>(null);
  const [customerParents, setCustomerParents] = useState<Customer[]>([]);
  const [customerChildren, setCustomerChildren] = useState<Customer[]>([]);
  const [disableCustomerPicker, setDisableCustomerPicker] = useState<boolean>(false);
  const history = useHistory();
  const client = useApolloClient();
  const { search, pathname } = useLocation() as unknown as ILocation;
  const [parentCustomerObject, setParentCustomerObject] = useState<Customer | null | undefined>(
    null,
  );
  const [childCustomer, setChildCustomer] = useState<string>('');
  const [parentsDropdownWidth, setParentsDropdownWidth] = useState<string>('');
  const [childrenDropdownWidth, setChildrenDropdownWidth] = useState<string>('');

  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: {
      sort: [
        {
          field: 'name',
          order: SortOrder.Asc,
        },
      ],
    },
  });

  const onCustomerChange = (newCustomerId: string, newCustomerName: string) => {
    if (customerChangeCallback !== undefined) {
      customerChangeCallback(newCustomerId, newCustomerName);
      return;
    }
    StorageUtils.setCustomerId(newCustomerId);
    StorageUtils.setCustomerName(newCustomerName);
    client
      .resetStore()
      .then(() => {
        return setDisableCustomerPicker(true);
      })
      .finally(() => {
        return setDisableCustomerPicker(false);
      });
  };

  const onParentCustomerChangeHandler = (value: string) => {
    setParentCustomerObject(getCustomerObj(value));
    setCustomerId(value);
    setChildCustomer(value);
  };

  const onChildCustomerChangeHandler = (value: string) => {
    setChildCustomer(value);
    setCustomerId(value);
  };

  const getCustomerObj = (itemId: string): Customer | null | undefined => {
    if (!customersData?.customers.items.length) {
      return null;
    }

    return customersData.customers.items.find(({ id }) => {
      return id === itemId;
    });
  };

  const isParentIncluded = (id: string) => {
    return customersData?.customers.items.find((element) => {
      return element.id === id;
    });
  };

  useEffect(() => {
    const children: Customer[] =
      customersData?.customers.items.filter((item) => {
        return item.parentId === parentCustomerObject?.id;
      }) || [];
    setCustomerChildren(children);
    if (children) {
      setChildrenDropdownWidth(getMinWidthValue(children));
    }
  }, [parentCustomerObject]);

  useEffect(() => {
    if (!customerObject) {
      return;
    }

    if (!customerObject?.parentId || !isParentIncluded(customerObject.parentId)) {
      onParentCustomerChangeHandler(customerId);
    } else {
      setParentCustomerObject(getCustomerObj(customerObject.parentId));
      onChildCustomerChangeHandler(customerId);
    }
    setCustomerName(customerObject.name);

    if (!customersLoading) {
      onCustomerChange(customerObject.id, customerObject.name);
    }
  }, [customerObject]);

  useEffect(() => {
    StorageUtils.setPlatformId(customersData?.customers?.items[0].platformId || '');
    const hasItems = !!customersData?.customers.items.length;
    const customerIsNotAvailable = (itemId: string) => {
      return hasItems && !getCustomerObj(itemId);
    };
    const queryStringData = parseQueryString(search);
    const paramsCustomerId = queryStringData?.customer_id;
    setCustomerObject(getCustomerObj(customerId));
    if (hasItems && paramsCustomerId) {
      const paramsCustomerData = getCustomerObj(paramsCustomerId);

      if (paramsCustomerData) {
        setCustomerId(paramsCustomerData.id);
        setCustomerName(paramsCustomerData.name);
      }

      delete queryStringData.customer_id;

      history.replace({
        pathname,
        search: new URLSearchParams(queryStringData).toString(),
      });
    }

    if (!customerId || customerId === 'null' || customerIsNotAvailable(customerId)) {
      setCustomerId(customersData?.customers.items[0].id as string);
    }

    if (!customerName || customerName === 'null') {
      setCustomerName(customersData?.customers.items[0].name as string);
    }

    const parents = customersData?.customers.items.filter((item) => {
      return !item.parentId || !isParentIncluded(item.parentId);
    });

    setCustomerParents(parents as Customer[]);

    if (parents) {
      setParentsDropdownWidth(getMinWidthValue(parents));
    }
  }, [customerId, customersData]);

  // get the min-width value for the select to have dropdown show full name of customers.
  const getMinWidthValue = (options: Customer[]) => {
    return `${
      Math.max(
        ...options.map((customer) => {
          return customer.name.length;
        }),
      ) * 10
    }px`;
  };

  return (
    <>
      <Select
        showSearch
        disabled={disableCustomerPicker}
        dropdownStyle={{ minWidth: parentsDropdownWidth }}
        placeholder="Customers"
        onChange={onParentCustomerChangeHandler}
        value={parentCustomerObject?.id}
        loading={customersLoading}
        size="large"
        className="customer-select mobile-select"
        filterOption={(input, option) => {
          return option?.optionlabel.toLowerCase().includes(input.toLowerCase());
        }}
      >
        {customerParents?.map(({ name, id }) => {
          return (
            <Option key={id} value={id} optionlabel={name}>
              {name}
            </Option>
          );
        })}
      </Select>
      {customerChildren?.length > 0 && (
        <Select
          showSearch
          disabled={disableCustomerPicker}
          dropdownStyle={{ minWidth: childrenDropdownWidth }}
          placeholder="Customers"
          onChange={onChildCustomerChangeHandler}
          value={childCustomer}
          loading={customersLoading}
          size="large"
          className="customer-select mt-2"
          filterOption={(input, option) => {
            return option?.optionlabel.toLowerCase().includes(input.toLowerCase());
          }}
        >
          <Option
            key={parentCustomerObject?.id as string}
            value={parentCustomerObject?.id as string}
            optionLabel={parentCustomerObject?.name}
          >
            {parentCustomerObject?.name}
          </Option>
          {customerChildren?.map(({ name, id }) => {
            return (
              <Option key={id} value={id} optionlabel={name}>
                {name}
              </Option>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default CustomerPicker;
