import React, { createContext, useEffect, useState } from 'react';
import { Drawer, Layout as AntdLayout, notification } from 'antd';
import { IWrapperProps, useTranslation } from 'utils';
import VideoNotification from '../pages/VideoReceptionPage/components/VideoReception/NotificationHandler/VideoNotification';
import InboxContextWrapper from '../utils/context/InboxContext/InboxContextWrapper';
import { ResponsiveView } from '../components';
import MainHeader from './MainHeader';
import Sidebar from './Sidebar';
import './Layout.scss';
import MobileDrawer from './MobileDrawer/MobileDrawer';
import { useLocation } from 'react-router-dom';
import { Message, useSocialConversationUpdatedSubscription } from '../models/graphql';
import { InboxSource } from '../types/inbox';

const { Content } = AntdLayout;
const mobileBreakpoint = 640;
export const IsMobileContext = createContext<boolean>(window.innerWidth < mobileBreakpoint);

const Layout: React.FC<IWrapperProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileBreakpoint);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const location = useLocation();

  let timer: ReturnType<typeof setTimeout>;
  const { data: updatedSocialConversations } = useSocialConversationUpdatedSubscription();
  const [prevMessage, setPrevMessage] = useState<Message | undefined>(undefined);
  const translate = useTranslation();

  useEffect(() => {
    const conversation = updatedSocialConversations?.SocialConversationUpdated;
    const lastMessage = conversation?.messages[0];
    if (
      conversation &&
      lastMessage?.sender.id === conversation?.createdBy?.id &&
      prevMessage?.messageTime !== lastMessage?.messageTime
    ) {
      notification.open({
        message: translate('newMessage'),
        duration: 0,
        style: { cursor: 'pointer' },
        description: `${conversation?.messages[0].sender.displayName}: ${conversation?.preview?.content}`,
        onClick: () => {
          window.location.href = `${window.location.origin}/messages/${InboxSource.SocialNetworks}/${conversation?.id}`;
        },
      });
    }
    setPrevMessage(lastMessage as Message);
  }, [updatedSocialConversations?.SocialConversationUpdated]);

  const toggle = (): void => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setCollapsed(false);
  }, [location]);

  const handleWindowSizeChange = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setIsMobile(window.innerWidth < mobileBreakpoint);
      setScreenSize(window.innerWidth);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <IsMobileContext.Provider value={isMobile}>
      <InboxContextWrapper>
        <AntdLayout
          className={`main-layout${collapsed ? ' sidebar-collapsed' : ''} ${
            isMobile ? ' is-mobile' : ''
          }`}
        >
          <VideoNotification />
          <ResponsiveView
            onMobile={
              <Drawer
                placement="left"
                closable={true}
                onClose={toggle}
                width={screenSize}
                visible={collapsed}
              >
                <MobileDrawer />
              </Drawer>
            }
            onDesktop={<Sidebar collapsed={collapsed} />}
          />
          <AntdLayout className="site-layout">
            <MainHeader collapsed={collapsed} toggle={toggle} />
            <React.StrictMode>
              <Content className="site-layout-background">{children}</Content>
            </React.StrictMode>
          </AntdLayout>
        </AntdLayout>
      </InboxContextWrapper>
    </IsMobileContext.Provider>
  );
};

export default Layout;
