import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Select } from 'antd';
import { GetCustomerCrmContactsQuery } from '../../models/graphql';
import { useTranslation } from '../../utils';
import { IObjectLiteral } from '../../interfaces/object-literal.interface';
import { useCrmContactOptions } from './options.hook';

import './CrmContactSelector.scss';

const { Option, OptGroup } = Select;

export enum CrmInformationType {
  Phonenumber = 'phone',
  Email = 'email',
}

export interface ICrmContactSelectorProps {
  containerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  buttonProps?: ButtonProps & React.RefAttributes<HTMLElement> & { text: string };
  informationType: CrmInformationType;
  crmContacts: GetCustomerCrmContactsQuery['customerCrmContacts'];
  value: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
  selectProps?: IObjectLiteral;
  placeholder?: string;
}

const InputWithButton: React.FC<ICrmContactSelectorProps> = ({
  containerProps = {},
  buttonProps = { text: 'No text' },
  selectProps = {},
  crmContacts,
  informationType,
  value,
  placeholder,
  setValue,
}) => {
  const options = useCrmContactOptions({
    crmContacts,
    informationType,
  });
  const { text, ...propsForButton } = buttonProps;
  const translate = useTranslation();

  return (
    <div className="input-with-button" {...containerProps}>
      {/* <Input {...inputProps} /> */}
      <Select
        {...selectProps}
        mode="tags"
        suffixIcon={<DownOutlined />}
        value={value}
        placeholder={placeholder ? translate(placeholder) : ''}
        onChange={(val) => {
          return setValue(val);
        }}
      >
        {options.map((optionGroup) => {
          return (
            <OptGroup key={optionGroup.key} label={optionGroup.label}>
              {optionGroup.options.map((option) => {
                return (
                  <Option key={option.key} value={option.value}>
                    {option.content}
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </Select>
      {!!Object.keys(propsForButton).length && <Button {...propsForButton}>{text}</Button>}
    </div>
  );
};

export default InputWithButton;
