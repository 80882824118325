import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { KeycloakProfile } from 'keycloak-js';
import { LANGUAGES } from '../../../constants';
import { appLanguageVar } from '../../../graphql/cache';
import {
  useGetCurrentUserQuery,
  useUpdateCurrentUserLocaleMutation,
} from '../../../models/graphql';
import { useTranslation } from '../../../utils';
import './LangueagePicker.scss';

interface ILanguagePickerProps {
  user?: KeycloakProfile;
}

const LanguagePicker: React.FC<ILanguagePickerProps> = ({ user }) => {
  const [language, setLanguage] = useState('');
  const translate = useTranslation();
  const { data: currentUser, loading: currentUserLoading } = useGetCurrentUserQuery();
  const [updateUserLocale, { loading: updateLoading }] = useUpdateCurrentUserLocaleMutation();

  useEffect(() => {
    const userLocale = currentUser?.getCurrentUser.locale || '';

    if (
      LANGUAGES.some((l) => {
        return l.key === userLocale;
      })
    ) {
      appLanguageVar(userLocale);
      setLanguage(userLocale);
    } else {
      onChangeLanguageHandler('nl');
    }
  }, [user]);

  const onChangeLanguageHandler = async (lang: string) => {
    if (!user) {
      return;
    }

    const result = await updateUserLocale({
      variables: {
        locale: lang,
      },
    });

    if (result.data?.updateCurrentUserLocale) {
      appLanguageVar(lang);

      setLanguage(lang);

      return;
    }

    message.error(translate('errorOccured'));
  };

  return (
    <div className="lang-select-container">
      <Select
        className="lang-select mobile-select"
        loading={currentUserLoading}
        onChange={onChangeLanguageHandler}
        disabled={updateLoading || currentUserLoading}
        value={language || currentUser?.getCurrentUser.locale!}
      >
        {LANGUAGES.map((lang) => {
          return (
            <Select.Option key={lang.value} value={lang.key}>
              {lang.key.toUpperCase()}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default LanguagePicker;
