import React from 'react';
import { DatePicker, Input, InputNumber, Select, Switch } from 'antd';
import { Rule } from 'antd/lib/form';
import { ConversationStructuredTypeField } from '../../models/graphql';
import { useTranslation } from '../../utils';

export const renderInput = (field: ConversationStructuredTypeField) => {
  const { fieldType, options, defaultValue } = field;
  const { name } = fieldType;

  switch (name) {
    case 'Keuzelijst':
    case 'Meerkeuzelijst':
      return (
        <Select
          placeholder={defaultValue}
          mode={name === 'Meerkeuzelijst' ? 'multiple' : undefined}
        >
          {options &&
            options.map((option) => {
              return (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              );
            })}
        </Select>
      );
    case 'Ja/Nee':
      return <Switch />;
    case 'Datum':
    case 'Datum en tijd':
    case 'Uur':
      return <DatePicker />;
    case 'Geheel getal':
      return <InputNumber />;
    default:
      return <Input placeholder={defaultValue || undefined} />;
  }
};

export const generateRules = (
  field: ConversationStructuredTypeField,
  translate: ReturnType<typeof useTranslation>,
): Rule[] => {
  const { fieldType, required } = field;
  const { regExForValidation, regExForValidChars, maxLength, errorMessage, name } = fieldType;

  const rulesArr: Rule[] = [];
  const validationRegExp = regExForValidation ? new RegExp(regExForValidation) : undefined;
  const charRegExp = regExForValidChars ? new RegExp(regExForValidChars) : undefined;
  const shouldHaveMaxLength = [
    'Datum',
    'Datum en tijd',
    'Uur',
    'Geheel getal',
    'Ja/Nee',
    'Meerkeuzelijst',
  ].some((el) => {
    return el === name;
  });

  if (required) {
    rulesArr.push({ required, message: translate('form.isrequired') });
  }
  if (maxLength && shouldHaveMaxLength) {
    rulesArr.push({ max: maxLength, message: translate('form.maxLengthExceeded') });
  }
  if (validationRegExp) {
    rulesArr.push({ pattern: validationRegExp, message: errorMessage as string });
  }
  if (charRegExp) {
    rulesArr.push({ pattern: charRegExp, message: errorMessage as string });
  }

  return rulesArr;
};

export const getStructuredFieldName = (field: ConversationStructuredTypeField) => {
  const { label } = field;

  return `${label.trim()}`;
};
