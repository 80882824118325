import { useState } from 'react';
import { ApolloClient, ApolloError, MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { RegisterWebpushMutation, RegisterWebpushMutationVariables } from '../../../models/graphql';
import { useTranslation } from '../../../utils';
import { GraphQLDocuments } from '../graphQLDocuments';
import { parseError } from '../../../utils/global/parseError';
import { client } from '../../../graphql/client';

const defaultRegisterWebpushOptions: MutationHookOptions<
  RegisterWebpushMutation,
  RegisterWebpushMutationVariables
> = {};

export const useRegisterWebpush = (
  options?: MutationHookOptions<RegisterWebpushMutation, RegisterWebpushMutationVariables>,
) => {
  const translate = useTranslation();
  const [hookState, setHookState] = useState<{
    isLoading: boolean;
    data?: RegisterWebpushMutation | null;
    error?: string;
  }>({
    isLoading: false,
  });

  const [mutation] = useMutation<RegisterWebpushMutation, RegisterWebpushMutationVariables>(
    GraphQLDocuments.registerWebpush,
    {
      client: client as unknown as ApolloClient<object>,
    },
  );

  const registerWebpush = async (variables: RegisterWebpushMutationVariables) => {
    setHookState({
      isLoading: true,
    });

    try {
      const response = await mutation({
        ...defaultRegisterWebpushOptions,
        ...options,
        variables,
      });

      setHookState({
        data: response.data,
        isLoading: false,
      });

      return response;
    } catch (error) {
      const parsedError = parseError(error as unknown as ApolloError, translate);

      setHookState({
        error: parsedError,
        isLoading: false,
      });

      return null;
    }
  };

  return {
    registerWebpushLoading: hookState.isLoading,
    registerWebpushError: hookState.error,
    registerWebpushData: hookState.data,
    registerWebpush,
  };
};
