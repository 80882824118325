import { ReactNode, createContext, useContext } from 'react';
import useNotificationNumberTabTitle from 'utils/hooks/useNotificationNumberTabTitle';

type MessageContextType = {
  setMessageCount: React.Dispatch<React.SetStateAction<number>>;
}

const MessageContext = createContext<MessageContextType | null>(null);

export default function MessageContextProvider({ children }: { children: ReactNode }) {
  const setMessageCount = useNotificationNumberTabTitle();

  return (
    <MessageContext.Provider value={{ setMessageCount }}>
      {children}
    </MessageContext.Provider>
  );
}

export function useMessageContext() {
  const context = useContext(MessageContext);

  if (context === null) {
    throw new Error('useMessageContext must be used within a MessageContextProvider');
  }

  return context;
}