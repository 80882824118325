import React from 'react';
import '../Sidebar/Sidebar.scss';
import './MobileDrawer.scss';
import Sidebar from '../Sidebar';

const MobileDrawer: React.FC = ({}) => {
  return (
    <div className="drawer-sidebar-container">
      <Sidebar collapsed={false} />
    </div>
  );
};

export default MobileDrawer;
