import { gql, SubscriptionHookOptions, useSubscription } from '@apollo/client';
import {
  useGetUserStatusQuery,
  UserStatusUpdatedSubscription,
  UserStatusUpdatedSubscriptionVariables,
} from 'models/graphql';

const STATUS_SUBSCRIPTION = gql`
  subscription userStatusUpdated {
    userStatusUpdated {
      active
    }
  }
`;

export const useUserStatusUpdated = (
  options?: SubscriptionHookOptions<
    UserStatusUpdatedSubscription,
    UserStatusUpdatedSubscriptionVariables
  >,
) => {
  const { data, error, loading } = useSubscription<
    UserStatusUpdatedSubscription,
    UserStatusUpdatedSubscriptionVariables
  >(STATUS_SUBSCRIPTION, options);

  const { data: initialUserStatus } = useGetUserStatusQuery({});

  return {
    userStatus: data?.userStatusUpdated.active || initialUserStatus?.userStatus.active,
    userStatusLoading: loading,
    userStatusError: error,
  };
};
