import React from 'react';
import { Modal } from '../index';
import MessageAnswer from '../MessageAnswer/MessageAnswer';
import './NewMessageModal.scss';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const NewMessageModal: React.FC<IProps> = ({ visible, setVisible }) => {
  const closeModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      className="message-answer-modal"
      title="New Message"
      visible={visible}
      onCancel={closeModal}
      content={<MessageAnswer isNewMessage closeModal={closeModal} />}
      footer={false}
    />
  );
};

export default NewMessageModal;
