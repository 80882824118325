import { startOfMonth, endOfMonth, startOfDay } from 'date-fns';

interface IDate {
  from: string;
  to: string;
}

export const getOneMonthRangeInDays = (month: number, year: number): IDate => {
  const firstDay = startOfMonth(new Date(year, month));
  const lastDay = startOfDay(endOfMonth(new Date(year, month)));

  const from = firstDay.toISOString();
  const to = lastDay.toISOString();

  return {
    from,
    to,
  };
};
