import { IObjectLiteral } from '../../../interfaces/object-literal.interface';
import { useCreateMessage } from './createMessage.hook';
import { ISendMessageOptions, useSendMessage } from './sendMessage.hook';

interface IChooseHandlerOptions {
  isNewMessage: boolean;
  options: ISendMessageOptions;
  getValues: () => Promise<IObjectLiteral>;
}

export const useChooseHandlers = ({
  isNewMessage = false,
  getValues,
  options,
}: IChooseHandlerOptions) => {
  const hook = isNewMessage ? useCreateMessage : useSendMessage;

  return hook(options, isNewMessage ? getValues : undefined);
};
