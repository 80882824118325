import React from 'react';
import reactStringReplace from 'react-string-replace';

export function highlightPhoneNumbers(text: string): Array<string | React.ReactNode> {
  const expr = [
    '[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}',
    '[0-9]{3}[0-9]{7}',
    '[0-9]{4} [0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}',
    '[0-9]{4} [(]0[)] [0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}',
    '[+][0-9]{4}[0-9]{7}',
    '[0-9]{4} [0-9]{2} [0-9]{2} [0-9]{2}',
    '[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}',
    '[0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}',
  ];

  return reactStringReplace(text, new RegExp(`(${expr.join('|')})`), (match, i) => {
    return (
      <a href={`tel:${match}`} className="clickable-phone-number">
        {match}
      </a>
    );
  });
}
