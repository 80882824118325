import React from 'react';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import './ActionIcons.scss';

interface IActionIconsProps {
  endIcon?: JSX.Element;
  onPrinterClick?: () => void;
  onExcelClick?: () => void;
  excelVisible?: boolean;
  pdfVisible?: boolean;
}

const ActionIcons: React.FC<IActionIconsProps> = ({
  endIcon,
  onPrinterClick,
  onExcelClick,
  excelVisible = true,
  pdfVisible = true,
}) => {
  return (
    <div className="action-icons">
      {pdfVisible && (
        <span onClick={onPrinterClick} role="button" tabIndex={0}>
          <FilePdfOutlined />
        </span>
      )}
      {excelVisible && (
        <span onClick={onExcelClick} role="button" tabIndex={0}>
          {' '}
          <FileExcelOutlined onClick={onExcelClick} />
        </span>
      )}
      {endIcon}
    </div>
  );
};

export default ActionIcons;
