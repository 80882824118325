import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from '../../utils';
import './EmptyIconComponent.scss';

const EmptyIconComponent: React.FC = () => {
  const translate = useTranslation();

  return (
    <div className="empty-container">
      <Empty description={translate('noDataTable')} />
    </div>
  );
};

export default EmptyIconComponent;
