import {
  useUpdateMeccaConversationMutation,
  useUpdateSocialConversationMutation,
} from 'models/graphql';
import { InboxSource } from 'types/inbox';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUpdateSingleConversation: any = (source: string) => {
  if (source === InboxSource.SocialNetworks) {
    return useUpdateSocialConversationMutation;
  }

  return useUpdateMeccaConversationMutation;
};
