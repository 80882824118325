import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { sentryConfig } from './environment';
import reportWebVitals from './reportWebVitals';
import App from './App';

const VERSION_KEY = 'MEMOWEB_VERSION';
const APP_VERSION = process.env.REACT_APP_VERSION || '';
const currentVersion = localStorage.getItem(VERSION_KEY);

if (currentVersion !== APP_VERSION) {
  localStorage.clear();
}

localStorage.setItem(VERSION_KEY, APP_VERSION || '');

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

Sentry.init({ ...sentryConfig, release: APP_VERSION });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
