import React, { ReactNode, useContext } from 'react';
import { IsMobileContext } from '../../../Layout/Layout';

interface IOwnProps {
  onMobile?: ReactNode;
  onDesktop?: ReactNode;
}

const ResponsiveView: React.FC<IOwnProps> = ({ onMobile, onDesktop }) => {
  const isMobile = useContext(IsMobileContext);

  return <>{isMobile ? onMobile : onDesktop}</>;
};

export default ResponsiveView;
