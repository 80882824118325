import { hasAllProperties } from './hasOwnProperty';

export const isError = (error: unknown): error is Error => {
  if (typeof error !== 'object') {
    return false;
  }

  if (error === null) {
    return false;
  }

  return hasAllProperties(error, ['name', 'message']);
};
