import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useTranslation } from '../../utils';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { Maybe } from '../../models/graphql';

interface IAttachmentPreview {
  url: string;
  fileName: Maybe<string>|undefined;
}

const AttachmentPreview: React.FC<IAttachmentPreview> = ({ url, fileName}) => {
  const [fileFormat, setFileFormat] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const translate = useTranslation();
  const videoFormats = ['mp4', 'webm'];
  const audioFormats = ['mpeg', 'wav'];
  const imageFormats = ['jpeg', 'png'];
  const textFormats = ['plain', 'html', 'txt', 'pdf'];

  useEffect(() => {
    const x = new XMLHttpRequest();
    x.onloadend = () => {
      setFileFormat(x.getResponseHeader('content-type') || '');
      setIsLoading(false);
    };
    x.open('GET', url, true);
    x.send();
  }, []);

  const isFileOfFormat = (fileFormats: string[]) => {
    for (const format of fileFormats) {
      if (fileFormat.includes(format)) {
        return true;
      }
    }
    return false;
  };

  if (isLoading) {
    return <Spin/>;
  }

  return (
    <>
      {
        isFileOfFormat(audioFormats) ? (
          <audio controls>
            <source src={url}/>
            <track kind="captions"/>
          </audio>
        )
        :
        isFileOfFormat(videoFormats) ? (
          <>
            <video controls>
              <source src={url}/>
              <track kind="captions"/>
            </video>
          </>
        )
        :
        isFileOfFormat(imageFormats) ? (
          <div className="image-preview-container">
            <img className="image-preview" src={url} alt={fileName ?? translate('attachmentPreview.imageAlt')}/>
          </div>
        )
        :
        isFileOfFormat(textFormats) ? (
          <iframe src={url}/>
        )
        :
        (
          <>
            <span>{translate('attachmentPreview.cantLoad')}</span>
            <br/>
            <Button
              type="text"
              icon={<DownloadOutlined/>}
              onClick={() => {
                saveAs(url, fileName ?? translate('attachmentPreview.noFileName'));
              }}
            />
          </>
        )
      }
    </>
  );
};

export default AttachmentPreview;
