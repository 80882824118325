import { EMAIL_REGEX, FAXNUMBER_REGEX, PHONENUMBER_REGEX } from './regex';

interface IValidationUtilsRegex {
  email: RegExp;
  phonenumber: RegExp;
  faxnumber: RegExp;
}

interface IIsValidProps {
  value: string | undefined;
  range?: [number, number];
  minLength?: number;
}

interface IIsValidTypeProps extends IIsValidProps {
  type: 'email' | 'phonenumber' | 'faxnumber';
}

export class ValidationUtils {
  private static regex: IValidationUtilsRegex = {
    email: EMAIL_REGEX,
    phonenumber: PHONENUMBER_REGEX,
    faxnumber: FAXNUMBER_REGEX,
  };

  public static isValidEmail({ value, range, minLength }: IIsValidProps): boolean {
    return this.isValid({ type: 'email', value, range, minLength });
  }

  public static isValidPhonenumber({ value, range, minLength }: IIsValidProps): boolean {
    return this.isValid({ type: 'phonenumber', value, range, minLength });
  }

  public static isValidFaxNumber({ value, range, minLength }: IIsValidProps): boolean {
    return this.isValid({ type: 'faxnumber', value, range, minLength });
  }

  private static isValid({ type, value, range, minLength }: IIsValidTypeProps): boolean {
    const regex = this.regex[type];
    const defaultRange: [number, number] = [0, 100];

    if (value && regex.test(value)) {
      if (minLength && value.length >= minLength) {
        return true;
      }

      if (range && value.length > range[0] && value.length <= range[1]) {
        return true;
      }

      if (
        !minLength &&
        !range &&
        value.length > defaultRange[0] &&
        value.length <= defaultRange[1]
      ) {
        return true;
      }
    }

    return false;
  }
}
