import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  BarChartOutlined,
  UserOutlined,
  InfoOutlined,
  AuditOutlined,
  DiffOutlined,
  ContactsOutlined,
  UsergroupAddOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { menuInboxBadgeCounterVar } from '../../graphql/cache';
import {
  DashBoardIcon,
  CallCenterIcon,
  SocialMediaIcon,
  InvoicesIcon,
  getPermission,
  AVAILABLE_ROLES,
  QUERY_BASED_ROLES,
  useTranslation,
} from '../../utils';
import CustomMenuBadge from './CustomMenuBadge';
import { ICustomMenuBadgeProps } from './CustomMenuBadge/CustomMenuBadge';
import ExpandIcon from './ExpandIcon/ExpandIcon';
import { useGetDoclrSettingsQuery } from '../../models/graphql';

interface ISidebarLinksChild {
  nameId: string;
  icon: JSX.Element;
  path: string;
  basePath?: string;
}

interface ISidebarLinks {
  nameId: string;
  icon: JSX.Element;
  path?: string;
  hasPermission: boolean | ((rules: string[]) => boolean);
  queryBasedPermission?: string;
  children?: ISidebarLinksChild[];
  basePath?: string;
}

export const getSiderbarLinks = (): ISidebarLinks[] => {
  return [
    {
      nameId: 'dashboard',
      icon: <DashBoardIcon />,
      path: '/',
      hasPermission: true,
    },
    {
      nameId: 'callCenter',
      icon: <CallCenterIcon />,
      hasPermission: true,
      path: '/messages/call-center',
      basePath: '/messages/call-center',
    },
    {
      nameId: 'socialMedia',
      icon: <SocialMediaIcon />,
      hasPermission: true,
      path: '/messages/social-networks',
      basePath: '/messages/social-networks',
    },
    {
      nameId: 'calendar',
      icon: <CalendarOutlined/>,
      hasPermission: true,
      path: '/calendar',
      queryBasedPermission: QUERY_BASED_ROLES.CAN_ACCESS_CALENDAR,
    },
    {
      nameId: 'invoices',
      icon: <InvoicesIcon />,
      path: '/invoices',
      hasPermission: getPermission([AVAILABLE_ROLES.SUPERADMIN, AVAILABLE_ROLES.ADMIN]),
      basePath: '/invoices',
    },
    {
      nameId: 'reports',
      icon: <BarChartOutlined />,
      path: '/reports/incoming',
      hasPermission: getPermission([AVAILABLE_ROLES.SUPERADMIN, AVAILABLE_ROLES.ADMIN]),
      basePath: '/reports',
    },
    {
      nameId: 'customerDetails',
      icon: <UserOutlined />,
      hasPermission: true,
      children: [
        {
          nameId: 'information',
          icon: <InfoOutlined />,
          path: '/customer/info',
          basePath: '/customer/info',
        },
        {
          nameId: 'instructions',
          icon: <AuditOutlined />,
          path: '/customer/instructions/fixed',
          basePath: '/customer/instructions',
        },
        {
          nameId: 'attachments',
          icon: <DiffOutlined />,
          path: '/customer/attachments',
          basePath: '/customer/attachments',
        },
        {
          nameId: 'crmContacts',
          icon: <ContactsOutlined />,
          path: '/customer/crmcontacts',
          basePath: '/customer/crmcontacts',
        },
      ],
    },
    {
      nameId: 'users',
      icon: <UsergroupAddOutlined />,
      path: '/settings/users',
      hasPermission: getPermission([AVAILABLE_ROLES.SUPERADMIN, AVAILABLE_ROLES.ADMIN]),
      basePath: '/settings/users',
    },
  ];
};

interface ISidebarMenuProps {
  collapsed: boolean;
}

const SidebarMenu: React.FC<ISidebarMenuProps> = ({ collapsed }) => {
  const translate = useTranslation();
  const location = useLocation();
  const [queryBasedPermissions, setQueryBasedPermissions] = useState<string[]>([]);

  const defaultOpenSubmenu = getSiderbarLinks().find((link) => {
    return link.children?.find((child) => {
      return child.path === location.pathname;
    });
  });

  const getMenuItemClassName = (basePath: string|undefined): string => {
    return basePath !== undefined && location.pathname.startsWith(basePath) ? 'ant-menu-item-selected' : '';
  };

  const { data: doclrConfig } = useGetDoclrSettingsQuery();

  useEffect(() => {
    if (doclrConfig?.getDoclrSettings.iframeUrl) {
      setQueryBasedPermissions((prevState) => {
        return [...prevState, QUERY_BASED_ROLES.CAN_ACCESS_CALENDAR];
      });
    } else {
      setQueryBasedPermissions(queryBasedPermissions.filter((permission) => {
        return permission !== QUERY_BASED_ROLES.CAN_ACCESS_CALENDAR;
      }));
    }
  }, [doclrConfig]);

  return (
    <Menu
      expandIcon={ExpandIcon}
      className={collapsed ? 'collapsed-menu' : ''}
      mode="inline"
      selectedKeys={[location.pathname]}
      defaultOpenKeys={[defaultOpenSubmenu?.nameId || '']}
    >
      {getSiderbarLinks().map(({ nameId, icon, children, path, hasPermission, queryBasedPermission, basePath }) => {
        if (!!queryBasedPermission && !queryBasedPermissions.includes(queryBasedPermission)) {
          return null;
        }

        if (!hasPermission) {
          return null;
        }

        if (children) {
          return (
            <Menu.SubMenu
              key={nameId}
              icon={<div>{icon}</div>}
              title={
                <div className="sidebar-link-container">
                  <span>{translate(nameId)}</span>
                </div>
              }
            >
              {children.map((child: ISidebarLinksChild) => {
                return (
                  <Menu.Item key={child.path} className={getMenuItemClassName(child.basePath)}>
                    <div className="sidebar-link-container">
                      <NavLink to={child.path}>
                        <div className="menu-item">
                          {child.icon} {translate(child.nameId)}{' '}
                        </div>
                      </NavLink>
                    </div>
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          );
        }

        const Badge = () => {
          const pagesWithBadges: Array<keyof typeof menuInboxBadgeCounterVar | string> = [
            'callCenter',
            'socialMedia',
          ];

          if (pagesWithBadges.includes(nameId)) {
            return <CustomMenuBadge pageName={nameId as ICustomMenuBadgeProps['pageName']} />;
          }

          return null;
        };

        return (
          <Menu.Item
            className={getMenuItemClassName(basePath)}
            key={path}
            icon={
              <NavLink to={path} className="sidebar-link">
                {icon}
              </NavLink>
            }
          >
            <div className="sidebar-link-container">
              <NavLink to={path} className="sidebar-link">
                <span className="sidebar-text">{translate(nameId)}</span>
                <Badge />
              </NavLink>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default SidebarMenu;
