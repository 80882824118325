import { useReactiveVar } from '@apollo/client';
import { menuInboxBadgeCounterVar } from 'graphql/cache';
import React, { useContext, useEffect } from 'react';
import { QueryHookOptions } from 'react-apollo';
import { useMessageContext } from 'utils/context/MessagesContext';
import {
  GetSocialConversationsPreviewQueryVariables,
  useGetMeccaConversationsPreviewLazyQuery,
  useGetSocialConversationsPreviewLazyQuery,
} from '../../../models/graphql';
import { InboxContext } from '../../../utils/context/InboxContext/inbox.context';
import './CustomMenuBadge.scss';

export interface ICustomMenuBadgeProps {
  pageName: keyof typeof menuInboxBadgeCounterVar;
}

const CustomMenuBadge: React.FC<ICustomMenuBadgeProps> = ({ pageName }) => {
  const { shouldRefetchInbox } = useContext(InboxContext);
  const [getSocialConversations, { data: socialConversations }] =
    useGetSocialConversationsPreviewLazyQuery();
  const [getMeccaConversations, { data: meccaConversations }] =
    useGetMeccaConversationsPreviewLazyQuery();
  const socialCount = useReactiveVar(menuInboxBadgeCounterVar.socialMedia);
  const callCenterCount = useReactiveVar(menuInboxBadgeCounterVar.callCenter);

  const variablesForUnread: QueryHookOptions<GetSocialConversationsPreviewQueryVariables> = {
    variables: {
      filter: {
        ...(pageName === 'callCenter' ? {} : { archived: false }),
        read: false,
      },
    },
  };

  const { setMessageCount } = useMessageContext();

  useEffect(() => {
    if (pageName === 'callCenter') {
      getMeccaConversations(variablesForUnread);
    }

    if (pageName === 'socialMedia') {
      getSocialConversations(variablesForUnread);
    }
  }, [pageName, shouldRefetchInbox]);

  useEffect(() => {
    const socialMessagesCount = socialConversations?.socialConversations?.count.toString() || socialCount;
    menuInboxBadgeCounterVar.socialMedia(socialMessagesCount);

    setMessageCount(+socialMessagesCount || 0);
  }, [socialConversations]);

  useEffect(() => {
    menuInboxBadgeCounterVar.callCenter(
      meccaConversations?.meccaConversations?.count.toString() || callCenterCount,
    );
  }, [meccaConversations]);

  return (
    <div className="custom-badge">
      <span>{pageName === 'socialMedia' ? socialCount : callCenterCount}</span>
    </div>
  );
};

export default CustomMenuBadge;
