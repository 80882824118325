import React, { createContext } from 'react';

interface IInboxContext {
  shouldRefetchInbox: boolean;
  inboxLoading: boolean;
  setInboxLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRefetchInbox: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InboxContext = createContext<IInboxContext>({
  shouldRefetchInbox: false,
  inboxLoading: false,
  setShouldRefetchInbox: () => {},
  setInboxLoading: () => {},
});
